/* eslint-disable vue/no-unused-vars */
<template>
    <v-container fluid grid-list-xl>
        <v-layout wrap>
            <v-flex xs12 md6 class="pb-0">
                <v-breadcrumbs class="mt-3 pl-1" :items="crumbs">
                    <v-icon slot="divider">chevron_right</v-icon>
                </v-breadcrumbs>
            </v-flex>
            <v-flex xs12 md3 offset-md3 pb-0>
                <v-select
                    v-if="hasStatement || hasName === 'object'"
                    v-model="selectedLanguage"
                    :items="languages"
                    item-text="label"
                    item-value="iso"
                    :label="$t('Item translation') + ' ' + $t(entity)"
                    class="mr-3"
                ></v-select>
            </v-flex>
            <v-flex xs12>
                <v-card>
                    <v-card-title class="pl-0">
                        <h3
                            class="text-h5 font-weight-thin primary--text pl-4 pr-1 pt-2"
                        >
                            {{ $t(entity) }}
                        </h3>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            prepend-icon="search"
                            label="Search"
                            single-line
                            hide-details
                            class="mr-3"
                        ></v-text-field>
                        <add-dialog
                            v-if="tenantPermissions.includes('edit masterdata')"
                            v-bind="$props"
                            :selectables="legendItems"
                        ></add-dialog>
                    </v-card-title>

                    <v-flex xs12 class="px-0 py-0">
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            :items="items"
                            class="elevation-0 master-data-table"
                            :search="search"
                            :options.sync="pagination"
                            :custom-filter="customFilter"
                        >
                            <template #[`item.id`]="{ item }">{{
                                item.id
                            }}</template>

                            <!-- CODE START -->
                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.code="props"
                            >
                                <v-edit-dialog
                                    :return-value.sync="props.item.code"
                                    lazy
                                    @save="save(props.item)"
                                >
                                    {{ props.item.code }}
                                    <template #input>
                                        <v-text-field
                                            slot="input"
                                            v-model="props.item.code"
                                            label="Edit"
                                            single-line
                                            counter
                                            @change="save(props.item)"
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else #item.code="props">
                                {{ props.item.code }}
                            </template>
                            <!-- CODE END -->

                             <!-- LANGUAGE START -->
                             <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.lang="props"
                            >
                                <v-edit-dialog
                                    :return-value.sync="props.item.lang"
                                    lazy
                                    @save="save(props.item)"
                                    
                                >   
                                    <div :class="'flag-icon flag-icon-' + props.item.lang"></div>
                                    <template #input style="minHeight: 200px" >
                                        <v-select
                                            v-model="props.item.lang"
                                            :items="[{ iso: 'de', name: 'Deutsch' }, { iso: 'fr', name: 'Französisch' }, { iso: 'it', name: 'Italienisch' }, { iso: 'en', name: 'Englisch'}]"
                                            item-value="iso"
                                            style="height: 220px" 
                                            item-text="name"
                                            chips
                                            label="Edit"
                                            single-line
                                            @input="save(props.item)">
                                        </v-select>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else #item.lang="props">
                                {{ props.item.lang }}
                            </template>
                            <!-- CODE END -->

                            <!-- TEMPLATE START -->
                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.template="props"
                            >
                                <v-edit-dialog
                                    :return-value.sync="props.item.template"
                                    lazy
                                    @save="save(props.item)"
                                >
                                    <span class="dotdotdot">{{ props.item.template }}</span>
                                    <template #input>
                                        <v-text-field
                                            slot="input"
                                            v-model="props.item.template"
                                            label="Edit"
                                            single-line
                                            counter
                                            @change="save(props.item)"
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else #item.template="props">
                                {{ props.item.template }}
                            </template>
                            <!-- CODE END -->

                            <!-- VALUE START -->
                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.value="props"
                            >
                                <v-edit-dialog
                                    :return-value.sync="props.item.value"
                                    lazy
                                    @save="save(props.item)"
                                >
                                    {{ props.item.value }}
                                    <template #input>
                                        <v-text-field
                                            slot="input"
                                            v-model="props.item.value"
                                            label="Edit"
                                            single-line
                                            counter
                                            @change="save(props.item)"
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else>
                                {{ props.item.value }}
                            </template>
                            <!-- VALUE END -->

                            <!-- Ingredient Legend Item START -->
                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.ingredient_legend_item_ids="props"
                            >
                                <v-edit-dialog

                                    @save="save(props.item)"
                                >
                                        <v-chip small outlined v-for="item in props.item.ingredient_legend_item_ids" :key="item">{{ props.item.selectable_legend_items.find(label => label.id === item).name[selectedLanguage] }}</v-chip>
                                        <template #input>
                                            <v-select
                                                v-model="props.item.ingredient_legend_item_ids"
                                                :items="props.item.selectable_legend_items"
                                                :item-text="item => item.name[selectedLanguage]"
                                                :item-value="item => item.id"
                                                chips
                                                multiple
                                                label="Edit"
                                                small-chips
                                                counter
                                                @change="save(props.item)"
                                            >
                                            </v-select>
                                        </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else>
                                <v-chip small outlined v-for="item in props.item.ingredient_legend_items" :key="item.id">{{ item.name[selectedLanguage] }}</v-chip>
                            </template>
                            <!-- Ingredient Legend Item END -->

                            <!-- FILE START -->
                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.file="props"
                            >
                                <img
                                    v-if="hasFile && props.item.file"
                                    class="table-image"
                                    :src="props.item.file.media.thumb"
                                    @click.stop="
                                        showDetail(props.item.file.media.large)
                                    "
                                />
                                <img
                                    v-else-if="
                                        hasTranslatableFile &&
                                            props.item.file[selectedLanguage]
                                    "
                                    class="table-image"
                                    :src="
                                        props.item.file[selectedLanguage].media
                                            .thumb
                                    "
                                    @click.stop="
                                        showDetail(
                                            props.item.file[selectedLanguage]
                                                .media.large
                                        )
                                    "
                                />
                            </template>

                            <template v-else #item.file="props">
                                <img
                                    v-if="hasFile && props.item.file"
                                    class="table-image"
                                    :src="props.item.file.media.thumb"
                                />
                                <img
                                    v-else-if="
                                        hasTranslatableFile &&
                                            props.item.file[selectedLanguage]
                                    "
                                    class="table-image"
                                    :src="
                                        props.item.file[selectedLanguage].media
                                            .thumb
                                    "
                                />
                            </template>
                            <!-- FILE END -->

                            <!-- DESCRIPTION START -->
                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.description="props"
                            >
                                <v-edit-dialog
                                    v-if="props.item.description"
                                    :return-value.sync="
                                        props.item.description[selectedLanguage]
                                    "
                                    @save="save(props.item)"
                                >
                                    {{
                                        props.item.description[selectedLanguage]
                                    }}
                                    <template #input>
                                        <v-text-field
                                            v-model="
                                                props.item.description[
                                                    selectedLanguage
                                                ]
                                            "
                                            label="Edit"
                                            single-line
                                            counter
                                            @change="save(props.item)"
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else #item.description="props">
                                {{ props.item.description[selectedLanguage] }}
                            </template>
                            <!-- DESCRIPTION END -->

                            <!-- STATEMENT START -->
                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.statement="props"
                            >
                                <v-edit-dialog
                                    v-if="props.item.statement"
                                    :return-value.sync="
                                        props.item.statement[selectedLanguage]
                                    "
                                    @save="save(props.item)"
                                >
                                    {{ props.item.statement[selectedLanguage] }}
                                    <template #input>
                                        <v-text-field
                                            v-model="
                                                props.item.statement[
                                                    selectedLanguage
                                                ]
                                            "
                                            label="Edit"
                                            single-line
                                            counter
                                            @change="save(props.item)"
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else #item.statement="props">
                                {{ props.item.statement[selectedLanguage] }}
                            </template>
                            <!-- STATEMENT END -->

                            <!-- NAME START -->
                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.name="props"
                            >
                                <v-edit-dialog
                                    v-if="hasName === 'object'"
                                    @save="save(props.item)"
                                >
                                    {{ props.item.name[selectedLanguage] }}
                                    <template #input>
                                        <v-text-field
                                            v-model="
                                                props.item.name[
                                                    selectedLanguage
                                                ]
                                            "
                                            label="Edit"
                                            single-line
                                            counter
                                            @change="save(props.item)"
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                                <v-edit-dialog
                                    v-else-if="hasName === 'string'"
                                    :return-value.sync="props.item.name"
                                    lazy
                                    @save="save(props.item)"
                                >
                                    {{ props.item.name }}
                                    <template #input>
                                        <v-text-field
                                            slot="input"
                                            v-model="props.item.name"
                                            label="Edit"
                                            single-line
                                            counter
                                            @change="save(props.item)"
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else #item.name="props">
                                <span v-if="hasName === 'object'">
                                    {{ props.item.name[selectedLanguage] }}
                                </span>
                                <span v-else-if="hasName === 'string'">
                                    {{ props.item.name }}
                                </span>
                            </template>
                            <!-- NAME END -->

                            <template #item.name_default_lang="props">
                                <span
                                    v-if="
                                        hasName &&
                                            defaultLanguageIso !=
                                                selectedLanguage
                                    "
                                    class="grey--text text--darken-1"
                                    >{{
                                        props.item.name[defaultLanguageIso]
                                    }}</span
                                >
                            </template>

                            <template #item.statement_default_lang="props">
                                <span
                                    v-if="
                                        hasStatement &&
                                            defaultLanguageIso !=
                                                selectedLanguage
                                    "
                                    class="grey--text text--darken-1"
                                    >{{
                                        props.item.statement[defaultLanguageIso]
                                    }}</span
                                >
                            </template>

                            <template #item.ingredient_legend_item_default_lang="props">
                                <span
                                    v-if="
                                        hasIngredientLegendItems &&
                                            defaultLanguageIso !=
                                                selectedLanguage
                                    "
                                    class="grey--text text--darken-1"
                                    >{{
                                        props.item.ingredient_legend_items.map(item => item.name[defaultLanguageIso])
                                    }}</span
                                >
                            </template>

                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.tenants="props"
                            >
                                <v-edit-dialog
                                    transition="slide-x-reverse-transition"
                                    eager
                                    @open="open('inlTenants')"
                                    @save="save"
                                >
                                    <v-chip
                                        v-for="t in props.item.tenants"
                                        :key="t.id"
                                        label
                                        class="small-table-chip black--text"
                                        small
                                        color="info"
                                        >{{ t.abbreviation }}</v-chip
                                    >
                                    <template #input>
                                        <v-select
                                            ref="inlTenants"
                                            v-model="props.item.tenants"
                                            :items="tenants"
                                            :item-text="
                                                'name.' + selectedLanguage
                                            "
                                            chips
                                            :label="$t('Tenants')"
                                            return-object
                                            multiple
                                            @input="save(props.item)"
                                        ></v-select>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else #item.tenants="props">
                                <v-chip
                                    v-for="t in props.item.tenants"
                                    :key="t.id"
                                    label
                                    class="small-table-chip black--text"
                                    small
                                    color="info"
                                    >{{ t.abbreviation }}</v-chip
                                >
                            </template>

                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.tenant="props"
                            >
                                <v-edit-dialog
                                    transition="slide-x-reverse-transition"
                                    eager
                                    @open="open('inlTenants')"
                                    @save="save"
                                >
                                    <v-chip
                                        label
                                        class="small-table-chip black--text"
                                        small
                                        color="info"
                                        >{{
                                            props.item.tenant.abbreviation
                                        }}</v-chip
                                    >
                                    <template #input>
                                        <v-select
                                            ref="inlTenants"
                                            v-model="props.item.tenant"
                                            :items="tenants"
                                            :item-text="
                                                'name.' + selectedLanguage
                                            "
                                            chips
                                            :label="$t('Tenant')"
                                            return-object
                                            @input="save(props.item)"
                                        ></v-select>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else #item.tenant="props">
                                <v-chip
                                    label
                                    class="small-table-chip black--text"
                                    small
                                    color="info"
                                    >{{
                                        props.item.tenant.abbreviation
                                    }}</v-chip
                                >
                            </template>

                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #item.brand="props"
                            >
                                <v-edit-dialog
                                    :return-value.sync="props.item"
                                    lazy
                                    @save="save(props.item)"
                                >
                                    <span v-if="props.item.brand">{{
                                        props.item.brand.name
                                    }}</span>
                                    <template #input>
                                        <v-select
                                            v-model="props.item.brand"
                                            :items="brands"
                                            item-text="name"
                                            chips
                                            return-object
                                            :label="$t('Brand')"
                                            @input="save(props.item)"
                                        ></v-select>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-else #item.brand="props">
                                <span v-if="props.item.brand">{{
                                    props.item.brand.name
                                }}</span>
                            </template>

                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata shops'
                                    )
                                "
                                #item.shops="props"
                            >
                                <v-chip
                                    label
                                    small
                                    class="small-table-chip black--text"
                                    :outlined="props.item.is_pro ? false : true"
                                    :color="
                                        props.item.is_pro
                                            ? 'accent'
                                            : 'grey darken1'
                                    "
                                    @click.stop="
                                        toggleField(props.item, 'is_pro')
                                    "
                                    >Pr</v-chip
                                >
                                <v-chip
                                    label
                                    small
                                    class="small-table-chip black--text"
                                    :outlined="
                                        props.item.is_hobby ? false : true
                                    "
                                    :color="
                                        props.item.is_hobby
                                            ? 'green'
                                            : 'grey darken1'
                                    "
                                    @click.stop="
                                        toggleField(props.item, 'is_hobby')
                                    "
                                    >Ho</v-chip
                                >
                                <v-chip
                                    label
                                    small
                                    class="small-table-chip black--text"
                                    :outlined="
                                        props.item.is_agric ? false : true
                                    "
                                    :color="
                                        props.item.is_agric
                                            ? 'yellow'
                                            : 'grey darken1'
                                    "
                                    @click.stop="
                                        toggleField(props.item, 'is_agric')
                                    "
                                    >Ag</v-chip
                                >
                            </template>

                            <template v-else #item.shops="props">
                                <v-chip
                                    label
                                    small
                                    class="small-table-chip black--text"
                                    :outlined="props.item.is_pro ? false : true"
                                    :color="
                                        props.item.is_pro
                                            ? 'accent'
                                            : 'grey darken1'
                                    "
                                    >Pr</v-chip
                                >
                                <v-chip
                                    label
                                    small
                                    class="small-table-chip black--text"
                                    :outlined="
                                        props.item.is_hobby ? false : true
                                    "
                                    :color="
                                        props.item.is_hobby
                                            ? 'green'
                                            : 'grey darken1'
                                    "
                                    >Ho</v-chip
                                >
                                <v-chip
                                    label
                                    small
                                    class="small-table-chip black--text"
                                    :outlined="
                                        props.item.is_agric ? false : true
                                    "
                                    :color="
                                        props.item.is_agric
                                            ? 'yellow'
                                            : 'grey darken1'
                                    "
                                    >Ag</v-chip
                                >
                            </template>

                            <template
                                v-if="
                                    tenantPermissions.includes(
                                        'edit masterdata'
                                    )
                                "
                                #[`item.file`]="{ item }"
                            >
                                <media-selector
                                    v-if="hasTranslatableFile"
                                    v-model="item.file[selectedLanguage]"
                                    :multiple="false"
                                    @change="save(item)"
                                >
                                    <template #opener="{ on }">
                                        <v-btn
                                            icon
                                            text
                                            small
                                            color="grey"
                                            class="mx-0"
                                            v-on="on"
                                        >
                                            <v-icon small>image</v-icon>
                                        </v-btn>
                                    </template>
                                </media-selector>

                                <media-selector
                                    v-if="hasFile"
                                    v-model="item.file"
                                    :multiple="false"
                                    @change="save(item)"
                                >
                                    <template #opener="{ on }">
                                        <v-btn
                                            icon
                                            text
                                            small
                                            color="grey"
                                            class="mx-0"
                                            v-on="on"
                                        >
                                            <v-icon small>image</v-icon>
                                        </v-btn>
                                    </template>
                                </media-selector>
                            </template>

                            <template v-else #[`item.file`]="{ item }">
                                <template v-if="hasTranslatableFile">
                                    <v-btn
                                        v-if="item.file[selectedLanguage]"
                                        icon
                                        text
                                        small
                                        color="grey"
                                        class="mx-0"
                                        @click.stop="
                                            showDetail(
                                                item.file[selectedLanguage]
                                                    .media.large
                                            )
                                        "
                                    >
                                        <v-icon small>image</v-icon>
                                    </v-btn>
                                </template>
                                <template v-else-if="hasFile">
                                    <v-btn
                                        v-if="item.file"
                                        icon
                                        text
                                        small
                                        color="grey"
                                        class="mx-0"
                                        @click.stop="
                                            showDetail(item.file.media.large)
                                        "
                                    >
                                        <v-icon small>image</v-icon>
                                    </v-btn>
                                </template>
                            </template>

                            <template #item.actions="props">
                                <v-btn
                                    v-if="
                                        tenantPermissions.includes(
                                            'delete masterdata'
                                        )
                                    "
                                    icon
                                    text
                                    color="grey"
                                    @click="confirmDelete(props.item)"
                                >
                                    <v-icon small>delete</v-icon>
                                </v-btn>
                            </template>

                            <template
                                v-if="
                                    props.item.name ||
                                        props.item.statement ||
                                        props.item.description
                                "
                                slot="items"
                                slot-scope="props"
                            >
                                <td class="text-right"></td>
                            </template>
                        </v-data-table>

                        <delete-dialog
                            :dialog="dialogDel"
                            :item="itemToDelete"
                            :caption="getCaption(itemToDelete)"
                            :entity="entity"
                            @close="dialogDel = false"
                        ></delete-dialog>

                        <lightbox
                            v-model="lightbox"
                            :src="lightboxSrc"
                            @close="lightbox = false"
                        ></lightbox>
                    </v-flex>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { availableLanguages, defaultLanguageIso } from "~/config";

export default {
    components: {
        addDialog: require("./AddDialog").default,
        deleteDialog: require("./DeleteDialog").default,
        lightbox: require("$comp/shared/Lightbox").default,
        mediaSelector: require("$comp/shared/MediaValetSelector").default
    },

    props: {
        entity: {
            type: String,
            required: true
        },
        hasName: {
            type: [String, Boolean],
            default: false
        },
        hasTenants: {
            type: Boolean,
            default: false
        },
        hasTenant: {
            type: Boolean,
            default: false
        },
        hasSectors: {
            type: Boolean,
            default: false
        },
        hasStatement: {
            type: Boolean,
            default: false
        },
        hasIngredientLegendItems: {
            type: Boolean,
            default: false
        },
        hasDescription: {
            type: Boolean,
            default: false
        },
        hasCode: {
            type: Boolean,
            default: false
        },
        hasValue: {
            type: Boolean,
            default: false
        },
        hasFile: {
            type: Boolean,
            default: false
        },
        hasTranslatableFile: {
            type: Boolean,
            default: false
        },
        hasProductType: {
            type: Boolean,
            default: false
        },
        hasBrand: {
            type: Boolean,
            default: false
        },
        hasTemplate: {
            type: Boolean,
            default: false
        },
        hasLanguage: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            slotProps: null,
            dialogDel: false,
            itemToDelete: {
                name: ""
            },
            search: "",
            moment: moment,
            selectedLanguage: defaultLanguageIso,
            defaultLanguageIso: defaultLanguageIso,
            loading: true,
            languages: availableLanguages,
            lightbox: false,
            lightboxSrc: "",
            pagination: {
                rowsPerPage: 25
            },
            newIndex: -1,
            newItem: {
                name: {}
            }
        };
    },

    computed: {
        ...mapGetters({
            tenants: "master-data/tenants",
            productTypes: "master-data/product-type",
            brands: "master-data/brand",
            isSuperAdmin: "auth/isSuperAdmin",
            permissions: "auth/permissions",
            tenant: "auth/tenant"
        }),

        items() {
            return this.$store.getters["master-data/" + this.entity];
        },

        tenantPermissions() {
            return this.permissions[this.tenant];
        },

        legendItems() {
            if(this.hasIngredientLegendItems && this.items.length > 0) {
                return this.items[0].selectable_legend_items;
            }
            return [];
        },

        headers: function() {
            let value = [
                {
                    text: this.$t("ID"),
                    value: "id",
                    width: "1%",
                    sortable: false
                }
            ];

            if (this.hasCode) {
                value.push({
                    text: this.$t("Code"),
                    value: "code",
                    sortable: false
                });
            }

            if (this.hasValue) {
                value.push({
                    text: this.$t("Value"),
                    value: "value",
                    sortable: false
                });
            }

            if (this.hasFile || this.hasTranslatableFile) {
                value.push({
                    text: this.$t("File"),
                    value: "file",
                    sortable: false
                });
            }

            if (this.hasName) {
                value.push({
                    text: this.$t("Name"),
                    value: "name",
                    sortable: false,
                    width: "40%"
                });
            }

            if (this.hasName && this.selectedLanguage != defaultLanguageIso) {
                value.push({
                    text:
                        this.$t("Name") +
                        " " +
                        this.$t("in " + this.defaultLanguageIso),
                    value: "name_default_lang",
                    sortable: false,
                    width: "40%"
                });
            }

            if (this.hasStatement) {
                value.push({
                    text: this.$t("Statement"),
                    value: "statement",
                    sortable: false,
                    width: "40%"
                });
            }

            if (this.hasDescription) {
                value.push({
                    text: this.$t("Beschreibung"),
                    value: "description",
                    sortable: false,
                    width: "40%"
                });
            }

            if (
                this.hasStatement &&
                this.selectedLanguage != defaultLanguageIso
            ) {
                value.push({
                    text:
                        this.$t("Statement") +
                        " " +
                        this.$t("in " + this.defaultLanguageIso),
                    value: "statement_default_lang",
                    sortable: false,
                    width: "40%"
                });
            }

            if (
                this.hasDescription &&
                this.selectedLanguage != defaultLanguageIso
            ) {
                value.push({
                    text:
                        this.$t("Description") +
                        " " +
                        this.$t("in " + this.defaultLanguageIso),
                    value: "description_default_lang",
                    sortable: false,
                    width: "40%"
                });
            }

            if (this.hasBrand) {
                value.push({
                    text: this.$t("Brand"),
                    value: "brand",
                    sortable: false
                });
            }

            if (this.hasIngredientLegendItems) {
                value.push({
                    text: this.$t("Ingredient Legend Items"),
                    value: "ingredient_legend_item_ids",
                    sortable: false
                });
            }

            if (
                this.hasSectors &&
                (this.isSuperAdmin ||
                    this.permissions[this.tenant].includes(
                        "edit masterdata shops"
                    ))
            ) {
                value.push({
                    text: this.$t("Shops"),
                    value: "shops",
                    align: "center",
                    sortable: false
                });
            }

            if (this.hasTemplate) {
                value.push({
                    text: this.$t("Template"),
                    value: "template",
                    sortable: false
                });
            }

            if (this.hasLanguage) {
                value.push({
                    text: this.$t("Language"),
                    value: "lang",
                    sortable: false
                });
            }

            /**

            if (this.isSuperAdmin && this.hasTenants) {
                value.push({
                    text: this.$t("Tenants"),
                    value: "tenants",
                    align: "left",
                    sortable: false
                });
            }

            if (this.isSuperAdmin && this.hasTenant) {
                value.push({
                    text: this.$t("Tenant"),
                    value: "tenant",
                    align: "left",
                    sortable: false
                });
            }


             */

            if (this.permissions[this.tenant].includes("edit masterdata")) {
                value.push({
                    text: this.$t("Actions"),
                    align: "right",
                    value: "actions",
                    sortable: false
                });
            }

            return value;
        },

        crumbs: function() {
            return [
                {
                    text: this.$t("Start"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t(this.entity),
                    disabled: true,
                    href: "/products"
                }
            ];
        }
    },

    watch: {
        entity: function() {
            this.loading = true;
            this.getData({ entity: this.entity, tenant: this.tenant }).then(
                () => {
                    this.loading = false;
                }
            );
        },
    },

    created() {
        this.loading = true;
        this.getData({ entity: this.entity, tenant: this.tenant }).then(() => {
            this.loading = false;
        });

        this.newItem = {};
        this.getTenants();

        if (this.productTypes.length < 1) {
            this.getData({ entity: "product-type", tenant: this.tenant });
        }

        if (this.brands.length < 1) {
            this.getData({ entity: "brand", tenant: this.tenant });
        }
    },

    methods: {
        ...mapActions({
            updateItem: "master-data/updateItem",
            saveNewItem: "master-data/saveNewItem",
            deleteItemPers: "master-data/deleteItem",
            getTenants: "master-data/getTenants",
            getData: "master-data/getData"
        }),

        save(item) {
            this.loading = true;
            let updatePayload = {
                entity: this.entity,
                item: item,
                fields: {
                    ...item
                }
            };

            if (this.hasBrand) {
                updatePayload.fields.brand_id = item.brand.id;
            }

            this.updateItem(updatePayload).finally(() => {
                this.loading = false;
                this.$emit("close");
                //or in file components
                this.$forceUpdate();
            });
        },

        confirmDelete(item) {
            this.itemToDelete = item;
            this.dialogDel = true;
        },

        showDetail(src) {
            this.lightboxSrc = src;
            this.lightbox = true;
        },

        deleteItem(item) {
            this.dialogDel = false;
            this.loading = true;
            this.deleteItemPers({ entity: this.entity, item: item })
                .then(() => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Item removed"),
                        color: "success"
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        toggleField(item, field) {
            this.loading = true;
            item[field] = !item[field];
            this.save(item);
        },

        getCaption(item) {
            if (this.hasName === "string" && item.name.length > 0) {
                return item.name;
            }

            if (
                this.hasName === "object" &&
                item.name[this.selectedLanguage] &&
                item.name[this.selectedLanguage].length > 0
            ) {
                return item.name[this.selectedLanguage];
            }

            if (this.hasCode && item.code) {
                return item.code;
            }

            if (
                this.hasStatement &&
                item.statement &&
                item.statement[this.selectedLanguage].length > 0
            ) {
                return item.statement[this.selectedLanguage];
            }

            if (
                this.hasIngredientLegendItems &&
                item.ingredient_legend_items &&
                item.ingredient_legend_items.length > 0
            ) {
                return item.ingredient_legend_items.map(item => item.name[this.selectedLanguage]);
            }

            if (
                this.hasDescription &&
                item.description &&
                item.description[this.selectedLanguage].length > 0
            ) {
                return item.description[this.selectedLanguage];
            }

            if (this.hasTemplate && item.template) {
                return item.template;
            }

            if (this.hasLanguage && item.lang) {
                return item.lang;
            }

            return item.id + "";
        },

        open(ref) {
            const item = this.$refs[ref];
            return item;
        },

        customFilter(value, search, item) {
            return Object.keys(item).some(k => {
                if (typeof item[k] === "string") {
                    return item[k].toLowerCase().includes(search.toLowerCase());
                } else if (
                    typeof item[k] === "object" &&
                    typeof item[k][this.selectedLanguage] === "string"
                ) {
                    return this.languages.some(
                        ({ iso }) =>
                            item[k][iso] &&
                            item[k][iso]
                                .toLowerCase()
                                .includes(search.toLowerCase())
                    );
                }
            });
        }
    }
};
</script>

<style scoped>
.dotdotdot {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    max-width: 400px;


}
</style>
