<template>
    <v-card class="elevation-0">
        <ProductPreviewLabel :open="previewOpen" @close="previewOpen = false" :previewPdf="previewPdf" />
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Print labels") }}
            </h3>
        </v-card-title>
        <v-card-text>
            <h4 class="text-h6 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Product identification Navision") }}
            </h4>
            <v-container py-4 px-4 grid-list-xs fluid>
                <v-layout wrap>
                    <v-flex xs12 sm12 md6>
                        <v-text-field
                            :value="product.item_number"
                            :label="$t('Product no')"
                            :disabled="true"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md6>
                        <v-text-field
                            :value="product.gtin"
                            placeholder=" "
                            :label="$t('GTIN')"
                            readonly
                            :disabled="true"
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md6>
                        <v-text-field
                            :value="product.name[language]"
                            placeholder=" "
                            :label="$t('Artikelname Navision')"
                            readonly
                            :disabled="true"
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md6>
                        <v-text-field
                            :value="brandCaption"
                            :label="$t('Brand')"
                            readonly
                            :disabled="true"
                            persistent-hint
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                </v-layout>
            </v-container>

            <h4 class="text-h6 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Existing labels") }}
            </h4>
            <v-container py-4 px-4 grid-list-xs fluid>
                <v-layout wrap>  
                    <v-simple-table id="existing-labels-table">
                        <template #default>
                        <thead>
                            <tr>
                            <th class="text-left">{{ $t('Template') }}</th>
                            <th class="text-left">{{ $t('Version') }}</th>
                            <th class="text-left">{{ $t('Date') }}</th>
                            <th class="text-left">{{ $t('Created by') }}</th>
                            <th class="text-right">{{ $t('Action') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, key) in existingVersionedLabels" :key="key">
                                <td>{{ item.label_template.name }}</td>
                                <td>{{ item.version_id === 0 ? 'Entwurf' : '' + item.version_id }}</td>
                                <td>{{ moment(item.created_at).format("DD.MM.YYYY HH:mm") }}</td>
                                <td>{{ item.created_by.name }}</td>
                                <td class="text-right">
                                    <v-btn
                                        text
                                        small
                                        color="black"
                                        class="mr-4"
                                        @click.stop="
                                            syncLabel(item.id)
                                        "
                                    >
                                        Laden
                                    </v-btn>
                                    <v-btn
                                        text
                                        small
                                        color="black"
                                        class="mx-0"
                                        @click.stop="
                                            downloadLabel(item.id)
                                        "
                                    >
                                        Download
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-layout>
            </v-container>

            <h4 class="text-h6 mt-6 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Template selection") }}
            </h4>

            <v-container py-4 px-4 grid-list-xs fluid>
                <v-row>
                    <v-col cols="6">
                        <v-select
                            v-model="selectedTemplateLanguage"
                            :items="templateLanguages"
                            :label="$t('Language')"
                            @input="printLabel.label_template_id = null"
                        >
                            <template #selection="{ item }">
                                <v-icon small :class="`flag-icon flag-icon-${item} mr-2`" />{{ $t(`language_${item}`) }}
                            </template>
                            <template #item="{ item }">
                                <v-icon small :class="`flag-icon flag-icon-${item} mr-2`" />{{ $t(`language_${item}`) }}
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="6">
                        <v-select
                            v-model="printLabel.label_template_id"
                            :items="availableTemplates"
                            item-text="name"
                            item-value="id"

                            :label="$t('Template')"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>

            <h4 class="text-h6 mt-6 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Declaration details") }}
            </h4>
            <v-container py-4 px-4 grid-list-xs fluid>
                <v-layout wrap>
                    <v-flex xs12 sm12 md8>
                        <h4 class="mt-6 font-weight-thin primary--text py-2">
                            {{ $t("Item number and Lot") }}
                        </h4>
                        <OverwriteableInput
                            :value="printLabel.item_number"
                            :label="$t('Item number')"
                            :default-value="product.item_number"
                            :disabled="false"
                            @input="update('item_number', $event)" 
                        />
                    </v-flex>
                    <v-flex xs12 sm12 md8>  
                        <v-text-field
                            v-model="printLabel.lot_number"
                            :label="$t('Lot number')"
                            :disabled="false"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <h4 class="mt-1 font-weight-thin primary--text py-2">
                            {{ $t("Product information") }}
                        </h4>
                        <OverwriteableInput
                            :value="printLabel.product_name"
                            :label="$t('Product name')"
                            :default-value="product.name ? product.name[language] : ''"
                            :disabled="false"
                            @input="update('product_name', $event)" 
                        />
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <OverwriteableInput
                            :value="printLabel.chemical_type"
                            :label="$t('Chemical Type on the label')"
                            :default-value="''"
                            :disabled="false"
                            @input="update('chemical_type', $event)" 
                        />
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <v-select
                            v-model="printLabel.fertilizer_category"
                            :label="$t('Fertilizer category')"
                            :items="attributes.fertilizer_categories"
                            item-text="code"
                            item-value="code" 
                        ></v-select>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <translateable
                            :default-language="selectedTemplateLanguage"
                        
                            v-model="printLabel.npk_information"
                            :multiline="false"
                            :label="$t('NPK information')"
                            :changeableLanguage="true"
                            :readonly="false"
                            :disabled="false"
                        >
                        </translateable>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <v-text-field
                            v-model="printLabel.grain_size"
                            :label="$t('Grain size')"
                            :disabled="false"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                        <nutrientsTable
                            ref="nutrients"
                            id="nutrients"
                            :value="printLabel.nutrients"
                            :language="nutrientsLanguage"
                            :readonly="false"
                            :item-label="$t('Nutrient')"
                            @click="onClick($event)"
                            @input="updatePrintLabel('nutrients', $event)"
                        >
                            <template #title>    
                                <span class="primary--text text-h6 underline">
                                    {{ $t("Nutrients") }}
                                    <v-btn text icon color="primary" @click="resetFromProduct('nutrients')">
                                        <v-icon small>cached</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                            <template #topMiddle>
                                <v-col cols="2">
                                    <v-select
                                        class="pt-4"
                                        v-model="nutrientsLanguage"
                                        :items="['de', 'fr', 'it']"
                                        :label="$t('Language')"
                                    >
                                        <template #selection="{ item }">
                                            <v-icon small :class="`flag-icon flag-icon-${item} mr-2`" />{{ $t(`language_${item}`) }}
                                        </template>
                                        <template #item="{ item }">
                                            <v-icon small :class="`flag-icon flag-icon-${item} mr-2`" />{{ $t(`language_${item}`) }}
                                        </template>
                                    </v-select>
                                </v-col>
                            </template>
                        </nutrientsTable>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                        <declarationsTable
                            ref="declarations"
                            id="declarations"
                            :value="printLabel.declarations"
                            :language="language"
                            :fallbackLanguage="fallbackLanguage"
                            :item-label="$t('Declarations')"
                            @click="onClick($event)"
                            @input="update('declarations', $event)"
                        >
                            <template #title>
                                <span class="primary--text text-h6 underline">
                                    {{ $t("Declarations") }}
                                </span>
                            </template>
                        </declarationsTable>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <h4 class="mt-1 font-weight-thin primary--text py-2">
                            {{ $t("Applicaton and storage") }}
                        </h4>
                        <v-textarea
                            v-model="printLabel.instructions"
                            :label="$t('Instructions')"
                            :disabled="false"
                        >
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <OverwriteableInput
                            :value="printLabel.dosage"
                            :label="$t('Dosage')"
                            :multiline="true"
                            :autogrow="true"
                            :default-value="product.dosage_text ? product.dosage_text[language] : ''"
                            :disabled="false"
                            @input="update('dosage', $event)"
                        />
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <v-text-field
                            v-model="printLabel.storage_conditions"
                            :label="$t('Storage conditions')"
                            :disabled="false"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <h4 class="mt-1 font-weight-thin primary--text py-2">
                            {{ $t("Net weight") }}
                        </h4>
                        <v-text-field
                            v-if="liquid === true"
                            v-model="printLabel.net_weight"
                            :label="$t('Flüssigdünger')"
                            :disabled="false"
                            step="0.01"
                            type="number"
                            suffix="Liter"
                        >
                        </v-text-field>
                        <v-text-field
                            v-else
                            v-model="printLabel.net_weight"
                            :label="$t('Festdünger')"
                            :disabled="false"
                            step="0.01"
                            type="number"
                            suffix="kg"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <h3 class="font-weight-thin">
                            {{ $t("CE symbol") }}
                        </h3>
                        <v-checkbox
                            v-model="printLabel.show_ce"
                            :label="$t('Show CE symbol')"
                            :disabled="false"
                        >
                        </v-checkbox>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <h3 class="font-weight-thin">
                            {{ $t("CD graphic") }}
                        </h3>
                        <media-selector
                            :value="printLabel.cd_graphic"
                            :multiple="false"
                            @change="update('cd_graphic', $event)"
                            @input="update('cd_graphic', $event)"
                        ></media-selector>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <h3 class="font-weight-thin">
                            {{ $t("Hazard symbols") }}
                        </h3>
                        <media-selector 
                            :value="printLabel.hazard_symbols"
                            lang="de"
                            :multiple="true"
                            @change="update('hazard_symbols', $event)"
                            @input="update('hazard_symbols', $event)"
                        ></media-selector>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <h3 class="font-weight-thin">
                            {{ $t("Safety and environmental information") }}
                        </h3>
                        <v-checkbox
                            v-model="printLabel.show_safety_info"
                            :label="$t('Show information on label')"
                            :disabled="false"
                        >
                        </v-checkbox>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <v-text-field
                            v-model="printLabel.ufi_code"
                            :label="$t('UFI code')"
                            :disabled="false"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md8>
                        <v-select
                            v-model="printLabel.distribution_id"
                            :items="attributes.distributions"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :disabled="false"
                            :label="$t('Distribution')"
                            small-chips
                            @input="update('distribution_id', $event)"
                        ></v-select>
                    </v-flex>
                </v-layout>
            </v-container>
            <v-card-actions>            
                <v-btn
                    color="gray darken-3"
                    outlined
                    @click="preview"
                    >{{ $t("Preview print label") }}</v-btn
                >
                <v-btn
                    :loading="saving"
                    color="success darken-3"
                    outlined
                    @click="storeLabelAsDraft"
                    >{{ $t("Save draft") }}</v-btn
                >
                <v-btn
                    :loading="saving"
                    color="success darken-3"
                    @click="storeLabel"
                    >{{ $t("Save as new Version") }}</v-btn
                >
                <v-spacer></v-spacer>
            </v-card-actions>            
        </v-card-text>
    </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { api } from "~/config";

export default {
    components: {
        // translateable: require("$comp/shared/TranslateableText").default,
        navisionIcon: require("$comp/ui/NavisionIcon").default,
        nutrientsTable: require("$comp/shared/HauertNutrientsTable").default,
        declarationsTable: require("$comp/shared/HauertDeclarations").default,
        translateable: require("$comp/shared/TranslateableText").default,
        OverwriteableInput: require("$comp/shared/OverwriteableInput").default,
        "media-selector": require("$comp/shared/MediaValetSelector").default,
        ProductPreviewLabel: require("$comp/product/edit/ProductPreviewLabel").default,
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            saving: false,
            previewOpen: false,
            previewPdf: '',
            moment: moment,
            liquid: this.product.is_liquid === true,
            nutrientsLanguage: this.language,
            printLabel: {
                product_id: this.product.id,
                item_number: this.product.item_number,
                distribution_id: null,
                lot_number: null,
                product_name: this.product.name[this.language],
                chemical_type: null,
                fertilizer_category: null,
                npk_information: [],
                nutrients: JSON.parse(JSON.stringify(this.product.nutrients)),
                grain_size: null,
                declarations: [],
                key_to_declarations: null,
                instructions: null,
                dosage: this.product.dosage_text[this.language],
                storage_conditions: null,
                net_weight: null,
                show_ce: false,
                cd_graphic: {},
                hazard_symbols: {},
                show_safety_info: false,
                ufi_code: null, 
                label_template_id: null,

            },
            selectedTemplate: null,
            selectedTemplateLanguage: null,
            templateLanguages: ["de", "fr"],
            existingLabels: this.product.print_labels
        };
    },
    computed: {
        ...mapGetters({
            attributes: "master-data/attributes"
        }),

        existingVersionedLabels() {
            return this.existingLabels
            // return this.existingLabels.filter(l => l.version_id !== 0)
        },

        draftLabel() {
            const d = this.existingLabels.filter(l => l.version_id === 0)
            return d.length > 0 ? d[0] : null
        },

        availableTemplates() {
            if (!this.selectedTemplateLanguage) {
                return []
            }


            return this.attributes.label_templates.filter(t => t.lang === this.selectedTemplateLanguage)
        },

        brandCaption() {
            return this.product.brand && this.product.brand.name
                ? this.product.brand.name
                : " ";
        }
    },
    watch: {
        selectedTemplateLanguage: function () {
            this.selectedTemplate = null;
            this.nutrientsLanguage = this.selectedTemplateLanguage;
        },
        
    },
    mounted() {
        // this.syncDraftLabel();
    },

    methods: {
        ...mapActions({
            storePrintLabel: "products/storePrintLabel",
        }),

        updatePrintLabel(field, value) {
            this.printLabel[field] = value;
        },

        update(field, value) {
            this.printLabel[field] = value;
        },

        async preview() {
            await axios.post(api.path("products.previewPrintLabels", {id: this.product.id}), this.printLabel)
                .then(response => {
                    this.previewPdf = 'data:application/pdf;base64,' + response.data;
                    this.previewOpen = true;
            });
        },

        syncDraftLabel(){
            if (this.draftLabel) {
                this.selectedTemplateLanguage = this.draftLabel.label_template.lang;
                this.printLabel = this.draftLabel
                this.$nextTick(() => {
                    this.printLabel.label_template_id = this.draftLabel.label_template_id;
                });
            }
        },

        storeLabel() {
            this.storePrintLabel({ id: this.product.id, data: this.printLabel} )
            this.$store.dispatch("ui/showSnackbar", {
                text: this.$t("Print label has been saved successfully"),
                color: "success"
            });
        },

        async downloadLabel(id) {
            const resp = await axios.get(api.path("products.downloadPrintLabel", {id: this.product.id, printLabelId: id}), {
                responseType: 'blob',
            })

             // create temp download url
            const downloadUrl = window.URL.createObjectURL(resp.data)

            // open pdf file on new tab
            window.open(downloadUrl, '__blank')

            // remove temp url
            window.URL.revokeObjectURL(downloadUrl)

            return resp
        },

        async syncLabel(id) {
            const resp = await axios.get(api.path("products.getPrintLabel", {id: this.product.id, printLabelId: id}))

            if (resp.status === 200) {
                this.selectedTemplateLanguage = resp.data.data.label_template.lang
                this.printLabel = {...resp.data.data}
                this.$store.dispatch("ui/showSnackbar", {
                    text: this.$t("Print label has been synced successfully"),
                    color: "success"
                });
                this.$nextTick(() => {
                    this.printLabel.label_template_id = resp.data.data.label_template_id;
                });
            }
        },

        storeLabelAsDraft() {
            const data = {
                ...this.printLabel,
                is_draft: true
            }
            this.storePrintLabel({ id: this.product.id, data: data} )
        },

        resetFromProduct(field) {
            this.printLabel[field] = this.product[field]
        }
        
    }
}
</script>

<style scoped>
#existing-labels-table {
    width: 100%;
}
</style>
