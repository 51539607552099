<template>
    <v-data-table
        :headers="headers"
        :items="items"
        dense
        :disable-pagination="false"
        :items-per-page="-1"
        :hide-default-footer="true"
        :class="id"
    >
        <template #top>
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <slot name="title"></slot>
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="768px">
                    <template #activator="{ on }">
                        <v-btn
                            :disabled="readonly"
                            outlined
                            x-small
                            color="primary"
                            v-on="on"
                        >
                            <v-icon small>add</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ title }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-select
                                            v-model="editedItem"
                                            autofocus
                                            :items="attributes.ingredients" 
                                            :item-text="
                                                item =>
                                                    item.name[language] ||
                                                    item.name[fallbackLanguage]
                                                "
                                            :label="$t('Ingredient')"
                                            clearable
                                            full-width
                                            return-object
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                            v-model="editedItem.custom_legend_text"
                                            :label="$t('Custom legend text')"
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-label>{{ $t('Legend') }}:</v-label>
                                        <span v-if="!editedItem.custom_legend_text && editedItem.ingredient_legend_items">
                                            {{ editedItem.ingredient_legend_items.map(item => item.name.de).join(', ') }} 
                                        </span>
                                        <span v-else-if="editedItem.custom_legend_text">
                                            {{ editedItem.custom_legend_text }} 
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions v-if="!readonly">
                            <v-spacer></v-spacer>
                            <v-btn color="error" text @click="close">{{
                                $t("Abbrechen")
                            }}</v-btn>
                            <v-btn color="primary" outlined @click="save">{{
                                $t("Speichern")
                            }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template #item.name="props">
            {{ props.item.name[language] }}
        </template>
        <template #item.legend="props">
            <span v-if="!props.item.custom_legend_text && props.item.ingredient_legend_items">
                {{ props.item.ingredient_legend_items.map(item => item.name.de).join(', ') }} 
            </span>
            <span v-else-if="props.item.custom_legend_text">
                {{ props.item.custom_legend_text }} 
            </span>
        </template>
        <template #item.action="{ item }">
            <v-btn icon :disabled="readonly" @click="editItem(item)">
                <v-icon small class="mr-2">edit</v-icon>
            </v-btn>

            <v-btn icon :disabled="readonly" @click="deleteItem(item)">
                <v-icon small>
                    delete
                </v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import Sortable from "sortablejs";
import { mapGetters } from "vuex";

export default {
    props: {
        language: {
            type: String,
            required: true
        },
        value: {
            type: [Array],
            default: () => []
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            dialog: false,
            renderIt: true,
            content: this.value,
            headers: [
                {
                    text: this.$t("Name"),
                    value: "name",
                    sortable: false
                },
                {
                    text: this.$t("Legend"),
                    value: "legend",
                    sortable: false
                },
                {
                    text: this.$t("Action"),
                    value: "action",
                    align: "right",
                    sortable: false
                }
            ],
            editedItem: {
                id: "",
                name: "",
                custom_legend_text: "",
            },
            defaultItem: {
                id: "",
                name: "",
                custom_legend_text: "",
            },
        };
    },
    computed: {
        ...mapGetters({
            product: "products/productToEdit",
            attributes: "master-data/attributes"
        }),
        items() {
            return this.value;
        },
        title() {
            return this.$t("Declarations") + " " + this.$t("add/edit")
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        value: {
            handler() {
                this.forceRerender();
            },
            deep: true
        }
    },
    mounted() {
        this.attachTableHook();
    },
    methods: {
        close() {
            this.attachTableHook();
            this.editedItem = { ...this.defaultItem };
            this.dialog = false;
        },
        deleteItem(item) {
            const index = this.value.indexOf(item);
            const copyVal = [...this.value];
            confirm(this.$t("Are you sure you want to delete this item?")) &&
                copyVal.splice(index, 1);

            this.$emit("input", copyVal);
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderIt = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderIt = true;
                this.attachTableHook();
            });
        },
        attachTableHook() {
            this.$nextTick(() => {
                let tables = document.querySelectorAll(".v-data-table." + this.id + " tbody");
                const _self = this;

                tables.forEach(table => {
                    Sortable.create(table, {
                        onEnd({ newIndex, oldIndex }) {
                            const copyVal = [..._self.value];
                            const rowSelected = copyVal.splice(
                                oldIndex,
                                1
                            )[0];
                            copyVal.splice(
                                newIndex,
                                0,
                                rowSelected
                            );
                            _self.forceRerender();
                            let cleanValue = _self.cleanup(copyVal);
                            _self.$emit("input", cleanValue);
                        }
                    });
                });
            });
        },
        save() {
            let copyVal = [...this.value];
            if (this.editedIndex > -1) {
                Object.assign(
                    copyVal[this.editedIndex],
                    this.editedItem
                );
                this.editedIndex = -1;
            } else {
                if (!Array.isArray(this.value)) {
                    const newItem = {
                        ...[this.editedItem]
                    };
                    copyVal = { ...this.value, ...newItem }
                } else {
                    copyVal.push(this.editedItem);
                }
            }
            const cleanValue = this.cleanup(copyVal);
            this.$emit("input", cleanValue);
            this.close();
        },
        editItem(item) {
            this.editedIndex = this.value.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        cleanup(value) {
            value.fr &&
                value.fr.forEach(item => {
                    delete item.children;
                });
            value.de &&
                value.de.forEach(item => {
                    delete item.children;
                });
            return value;
        },
    }
};
</script>
