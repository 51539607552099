var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"pb-0",attrs:{"xs12":"","md6":""}},[_c('v-breadcrumbs',{staticClass:"mt-3 pl-1",attrs:{"items":_vm.crumbs}},[_c('v-icon',{attrs:{"slot":"divider"},slot:"divider"},[_vm._v("chevron_right")])],1)],1),_c('v-flex',{attrs:{"xs12":"","md3":"","offset-md3":"","pb-0":""}},[(_vm.hasStatement || _vm.hasName === 'object')?_c('v-select',{staticClass:"mr-3",attrs:{"items":_vm.languages,"item-text":"label","item-value":"iso","label":_vm.$t('Item translation') + ' ' + _vm.$t(_vm.entity)},model:{value:(_vm.selectedLanguage),callback:function ($$v) {_vm.selectedLanguage=$$v},expression:"selectedLanguage"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pl-0"},[_c('h3',{staticClass:"text-h5 font-weight-thin primary--text pl-4 pr-1 pt-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.entity))+" ")]),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"prepend-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.tenantPermissions.includes('edit masterdata'))?_c('add-dialog',_vm._b({attrs:{"selectables":_vm.legendItems}},'add-dialog',_vm.$props,false)):_vm._e()],1),_c('v-flex',{staticClass:"px-0 py-0",attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-0 master-data-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.pagination,"custom-filter":_vm.customFilter},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.id))]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.code",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.code,"lazy":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "code", $event)},"update:return-value":function($event){return _vm.$set(props.item, "code", $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"slot":"input","label":"Edit","single-line":"","counter":""},on:{"change":function($event){return _vm.save(props.item)}},slot:"input",model:{value:(props.item.code),callback:function ($$v) {_vm.$set(props.item, "code", $$v)},expression:"props.item.code"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.code)+" ")])]}}:{key:"item.code",fn:function(props){return [_vm._v(" "+_vm._s(props.item.code)+" ")]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.lang",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.lang,"lazy":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "lang", $event)},"update:return-value":function($event){return _vm.$set(props.item, "lang", $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{staticStyle:{"height":"220px"},attrs:{"items":[{ iso: 'de', name: 'Deutsch' }, { iso: 'fr', name: 'Französisch' }, { iso: 'it', name: 'Italienisch' }, { iso: 'en', name: 'Englisch'}],"item-value":"iso","item-text":"name","chips":"","label":"Edit","single-line":""},on:{"input":function($event){return _vm.save(props.item)}},model:{value:(props.item.lang),callback:function ($$v) {_vm.$set(props.item, "lang", $$v)},expression:"props.item.lang"}})]},proxy:true}],null,true)},[_c('div',{class:'flag-icon flag-icon-' + props.item.lang})])]}}:{key:"item.lang",fn:function(props){return [_vm._v(" "+_vm._s(props.item.lang)+" ")]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.template",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.template,"lazy":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "template", $event)},"update:return-value":function($event){return _vm.$set(props.item, "template", $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"slot":"input","label":"Edit","single-line":"","counter":""},on:{"change":function($event){return _vm.save(props.item)}},slot:"input",model:{value:(props.item.template),callback:function ($$v) {_vm.$set(props.item, "template", $$v)},expression:"props.item.template"}})]},proxy:true}],null,true)},[_c('span',{staticClass:"dotdotdot"},[_vm._v(_vm._s(props.item.template))])])]}}:{key:"item.template",fn:function(props){return [_vm._v(" "+_vm._s(props.item.template)+" ")]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.value",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.value,"lazy":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "value", $event)},"update:return-value":function($event){return _vm.$set(props.item, "value", $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"slot":"input","label":"Edit","single-line":"","counter":""},on:{"change":function($event){return _vm.save(props.item)}},slot:"input",model:{value:(props.item.value),callback:function ($$v) {_vm.$set(props.item, "value", $$v)},expression:"props.item.value"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.value)+" ")])]}}:{key:"default",fn:function(undefined){return [_vm._v(" "+_vm._s(_vm.props.item.value)+" ")]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.ingredient_legend_item_ids",fn:function(props){return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":props.item.selectable_legend_items,"item-text":function (item) { return item.name[_vm.selectedLanguage]; },"item-value":function (item) { return item.id; },"chips":"","multiple":"","label":"Edit","small-chips":"","counter":""},on:{"change":function($event){return _vm.save(props.item)}},model:{value:(props.item.ingredient_legend_item_ids),callback:function ($$v) {_vm.$set(props.item, "ingredient_legend_item_ids", $$v)},expression:"props.item.ingredient_legend_item_ids"}})]},proxy:true}],null,true)},_vm._l((props.item.ingredient_legend_item_ids),function(item){return _c('v-chip',{key:item,attrs:{"small":"","outlined":""}},[_vm._v(_vm._s(props.item.selectable_legend_items.find(function (label) { return label.id === item; }).name[_vm.selectedLanguage]))])}),1)]}}:{key:"default",fn:function(undefined){return _vm._l((_vm.props.item.ingredient_legend_items),function(item){return _c('v-chip',{key:item.id,attrs:{"small":"","outlined":""}},[_vm._v(_vm._s(item.name[_vm.selectedLanguage]))])})}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.file",fn:function(props){return [(_vm.hasFile && props.item.file)?_c('img',{staticClass:"table-image",attrs:{"src":props.item.file.media.thumb},on:{"click":function($event){$event.stopPropagation();return _vm.showDetail(props.item.file.media.large)}}}):(
                                    _vm.hasTranslatableFile &&
                                        props.item.file[_vm.selectedLanguage]
                                )?_c('img',{staticClass:"table-image",attrs:{"src":props.item.file[_vm.selectedLanguage].media
                                        .thumb},on:{"click":function($event){$event.stopPropagation();return _vm.showDetail(
                                        props.item.file[_vm.selectedLanguage]
                                            .media.large
                                    )}}}):_vm._e()]}}:{key:"item.file",fn:function(props){return [(_vm.hasFile && props.item.file)?_c('img',{staticClass:"table-image",attrs:{"src":props.item.file.media.thumb}}):(
                                    _vm.hasTranslatableFile &&
                                        props.item.file[_vm.selectedLanguage]
                                )?_c('img',{staticClass:"table-image",attrs:{"src":props.item.file[_vm.selectedLanguage].media
                                        .thumb}}):_vm._e()]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.description",fn:function(props){return [(props.item.description)?_c('v-edit-dialog',{attrs:{"return-value":props.item.description[_vm.selectedLanguage]},on:{"update:returnValue":function($event){return _vm.$set(props.item.description, _vm.selectedLanguage, $event)},"update:return-value":function($event){return _vm.$set(props.item.description, _vm.selectedLanguage, $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":""},on:{"change":function($event){return _vm.save(props.item)}},model:{value:(
                                            props.item.description[
                                                _vm.selectedLanguage
                                            ]
                                        ),callback:function ($$v) {_vm.$set(props.item.description, 
                                                _vm.selectedLanguage
                                            , $$v)},expression:"\n                                            props.item.description[\n                                                selectedLanguage\n                                            ]\n                                        "}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.description[_vm.selectedLanguage])+" ")]):_vm._e()]}}:{key:"item.description",fn:function(props){return [_vm._v(" "+_vm._s(props.item.description[_vm.selectedLanguage])+" ")]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.statement",fn:function(props){return [(props.item.statement)?_c('v-edit-dialog',{attrs:{"return-value":props.item.statement[_vm.selectedLanguage]},on:{"update:returnValue":function($event){return _vm.$set(props.item.statement, _vm.selectedLanguage, $event)},"update:return-value":function($event){return _vm.$set(props.item.statement, _vm.selectedLanguage, $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":""},on:{"change":function($event){return _vm.save(props.item)}},model:{value:(
                                            props.item.statement[
                                                _vm.selectedLanguage
                                            ]
                                        ),callback:function ($$v) {_vm.$set(props.item.statement, 
                                                _vm.selectedLanguage
                                            , $$v)},expression:"\n                                            props.item.statement[\n                                                selectedLanguage\n                                            ]\n                                        "}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.statement[_vm.selectedLanguage])+" ")]):_vm._e()]}}:{key:"item.statement",fn:function(props){return [_vm._v(" "+_vm._s(props.item.statement[_vm.selectedLanguage])+" ")]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.name",fn:function(props){return [(_vm.hasName === 'object')?_c('v-edit-dialog',{on:{"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":""},on:{"change":function($event){return _vm.save(props.item)}},model:{value:(
                                            props.item.name[
                                                _vm.selectedLanguage
                                            ]
                                        ),callback:function ($$v) {_vm.$set(props.item.name, 
                                                _vm.selectedLanguage
                                            , $$v)},expression:"\n                                            props.item.name[\n                                                selectedLanguage\n                                            ]\n                                        "}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.name[_vm.selectedLanguage])+" ")]):(_vm.hasName === 'string')?_c('v-edit-dialog',{attrs:{"return-value":props.item.name,"lazy":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "name", $event)},"update:return-value":function($event){return _vm.$set(props.item, "name", $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"slot":"input","label":"Edit","single-line":"","counter":""},on:{"change":function($event){return _vm.save(props.item)}},slot:"input",model:{value:(props.item.name),callback:function ($$v) {_vm.$set(props.item, "name", $$v)},expression:"props.item.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.name)+" ")]):_vm._e()]}}:{key:"item.name",fn:function(props){return [(_vm.hasName === 'object')?_c('span',[_vm._v(" "+_vm._s(props.item.name[_vm.selectedLanguage])+" ")]):(_vm.hasName === 'string')?_c('span',[_vm._v(" "+_vm._s(props.item.name)+" ")]):_vm._e()]}},{key:"item.name_default_lang",fn:function(props){return [(
                                    _vm.hasName &&
                                        _vm.defaultLanguageIso !=
                                            _vm.selectedLanguage
                                )?_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(props.item.name[_vm.defaultLanguageIso]))]):_vm._e()]}},{key:"item.statement_default_lang",fn:function(props){return [(
                                    _vm.hasStatement &&
                                        _vm.defaultLanguageIso !=
                                            _vm.selectedLanguage
                                )?_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(props.item.statement[_vm.defaultLanguageIso]))]):_vm._e()]}},{key:"item.ingredient_legend_item_default_lang",fn:function(props){return [(
                                    _vm.hasIngredientLegendItems &&
                                        _vm.defaultLanguageIso !=
                                            _vm.selectedLanguage
                                )?_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(props.item.ingredient_legend_items.map(function (item) { return item.name[_vm.defaultLanguageIso]; })))]):_vm._e()]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.tenants",fn:function(props){return [_c('v-edit-dialog',{attrs:{"transition":"slide-x-reverse-transition","eager":""},on:{"open":function($event){return _vm.open('inlTenants')},"save":_vm.save},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{ref:"inlTenants",attrs:{"items":_vm.tenants,"item-text":'name.' + _vm.selectedLanguage,"chips":"","label":_vm.$t('Tenants'),"return-object":"","multiple":""},on:{"input":function($event){return _vm.save(props.item)}},model:{value:(props.item.tenants),callback:function ($$v) {_vm.$set(props.item, "tenants", $$v)},expression:"props.item.tenants"}})]},proxy:true}],null,true)},_vm._l((props.item.tenants),function(t){return _c('v-chip',{key:t.id,staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","color":"info"}},[_vm._v(_vm._s(t.abbreviation))])}),1)]}}:{key:"item.tenants",fn:function(props){return _vm._l((props.item.tenants),function(t){return _c('v-chip',{key:t.id,staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","color":"info"}},[_vm._v(_vm._s(t.abbreviation))])})}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.tenant",fn:function(props){return [_c('v-edit-dialog',{attrs:{"transition":"slide-x-reverse-transition","eager":""},on:{"open":function($event){return _vm.open('inlTenants')},"save":_vm.save},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{ref:"inlTenants",attrs:{"items":_vm.tenants,"item-text":'name.' + _vm.selectedLanguage,"chips":"","label":_vm.$t('Tenant'),"return-object":""},on:{"input":function($event){return _vm.save(props.item)}},model:{value:(props.item.tenant),callback:function ($$v) {_vm.$set(props.item, "tenant", $$v)},expression:"props.item.tenant"}})]},proxy:true}],null,true)},[_c('v-chip',{staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","color":"info"}},[_vm._v(_vm._s(props.item.tenant.abbreviation))])],1)]}}:{key:"item.tenant",fn:function(props){return [_c('v-chip',{staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","color":"info"}},[_vm._v(_vm._s(props.item.tenant.abbreviation))])]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.brand",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item,"lazy":""},on:{"update:returnValue":function($event){return _vm.$set(props, "item", $event)},"update:return-value":function($event){return _vm.$set(props, "item", $event)},"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.brands,"item-text":"name","chips":"","return-object":"","label":_vm.$t('Brand')},on:{"input":function($event){return _vm.save(props.item)}},model:{value:(props.item.brand),callback:function ($$v) {_vm.$set(props.item, "brand", $$v)},expression:"props.item.brand"}})]},proxy:true}],null,true)},[(props.item.brand)?_c('span',[_vm._v(_vm._s(props.item.brand.name))]):_vm._e()])]}}:{key:"item.brand",fn:function(props){return [(props.item.brand)?_c('span',[_vm._v(_vm._s(props.item.brand.name))]):_vm._e()]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata shops'
                                )
                            )?{key:"item.shops",fn:function(props){return [_c('v-chip',{staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","outlined":props.item.is_pro ? false : true,"color":props.item.is_pro
                                        ? 'accent'
                                        : 'grey darken1'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleField(props.item, 'is_pro')}}},[_vm._v("Pr")]),_c('v-chip',{staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","outlined":props.item.is_hobby ? false : true,"color":props.item.is_hobby
                                        ? 'green'
                                        : 'grey darken1'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleField(props.item, 'is_hobby')}}},[_vm._v("Ho")]),_c('v-chip',{staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","outlined":props.item.is_agric ? false : true,"color":props.item.is_agric
                                        ? 'yellow'
                                        : 'grey darken1'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleField(props.item, 'is_agric')}}},[_vm._v("Ag")])]}}:{key:"item.shops",fn:function(props){return [_c('v-chip',{staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","outlined":props.item.is_pro ? false : true,"color":props.item.is_pro
                                        ? 'accent'
                                        : 'grey darken1'}},[_vm._v("Pr")]),_c('v-chip',{staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","outlined":props.item.is_hobby ? false : true,"color":props.item.is_hobby
                                        ? 'green'
                                        : 'grey darken1'}},[_vm._v("Ho")]),_c('v-chip',{staticClass:"small-table-chip black--text",attrs:{"label":"","small":"","outlined":props.item.is_agric ? false : true,"color":props.item.is_agric
                                        ? 'yellow'
                                        : 'grey darken1'}},[_vm._v("Ag")])]}},(
                                _vm.tenantPermissions.includes(
                                    'edit masterdata'
                                )
                            )?{key:"item.file",fn:function(ref){
                            var item = ref.item;
return [(_vm.hasTranslatableFile)?_c('media-selector',{attrs:{"multiple":false},on:{"change":function($event){return _vm.save(item)}},scopedSlots:_vm._u([{key:"opener",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","text":"","small":"","color":"grey"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("image")])],1)]}}],null,true),model:{value:(item.file[_vm.selectedLanguage]),callback:function ($$v) {_vm.$set(item.file, _vm.selectedLanguage, $$v)},expression:"item.file[selectedLanguage]"}}):_vm._e(),(_vm.hasFile)?_c('media-selector',{attrs:{"multiple":false},on:{"change":function($event){return _vm.save(item)}},scopedSlots:_vm._u([{key:"opener",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","text":"","small":"","color":"grey"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("image")])],1)]}}],null,true),model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}}):_vm._e()]}}:{key:"item.file",fn:function(ref){
                            var item = ref.item;
return [(_vm.hasTranslatableFile)?[(item.file[_vm.selectedLanguage])?_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","text":"","small":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.showDetail(
                                            item.file[_vm.selectedLanguage]
                                                .media.large
                                        )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("image")])],1):_vm._e()]:(_vm.hasFile)?[(item.file)?_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","text":"","small":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.showDetail(item.file.media.large)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("image")])],1):_vm._e()]:_vm._e()]}},{key:"item.actions",fn:function(props){return [(
                                    _vm.tenantPermissions.includes(
                                        'delete masterdata'
                                    )
                                )?_c('v-btn',{attrs:{"icon":"","text":"","color":"grey"},on:{"click":function($event){return _vm.confirmDelete(props.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1):_vm._e()]}},{key:"items",fn:function(props){return (
                                props.item.name ||
                                    props.item.statement ||
                                    props.item.description
                            )?[_c('td',{staticClass:"text-right"})]:undefined}}],null,true)}),_c('delete-dialog',{attrs:{"dialog":_vm.dialogDel,"item":_vm.itemToDelete,"caption":_vm.getCaption(_vm.itemToDelete),"entity":_vm.entity},on:{"close":function($event){_vm.dialogDel = false}}}),_c('lightbox',{attrs:{"src":_vm.lightboxSrc},on:{"close":function($event){_vm.lightbox = false}},model:{value:(_vm.lightbox),callback:function ($$v) {_vm.lightbox=$$v},expression:"lightbox"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }