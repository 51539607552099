/* eslint-disable no-undef */
// Set env data via laravel
export const siteUrl = process.env.VUE_APP_URL,
    apiUrl = process.env.VUE_APP_API_URL,
    appEnv = process.env.VUE_APP_ENV,
    mediavaletAuthUrl = process.env.VUE_APP_MEDIAVALET_AUTH_URL,
    mediavaletAutUsername = process.env.VUE_APP_MEDIAVALET_AUTH_USERNAME,
    mediavaletAutPassword = process.env.VUE_APP_MEDIAVALET_AUTH_PASSWORD,
    mediavaletAutClientId = process.env.VUE_APP_MEDIAVALET_AUTH_CLIENT_ID,
    mediavaletApiUrl = process.env.VUE_APP_MEDIAVALET_API_URL;

// Export the site name
export const settings = {
    siteName: process.env.VUE_APP_NAME,
    apiUrl: process.env.VUE_APP_URL
};

// Helper function for URL handling
class URL {
    constructor(base) {
        this.base = base;
    }

    path(path, args) {
        path = path.split(".");
        let obj = this,
            url = this.base;

        for (let i = 0; i < path.length && obj; i++) {
            if (obj.url) {
                url += "/" + obj.url;
            }

            obj = obj[path[i]];
        }
        if (obj) {
            url = url + "/" + (typeof obj === "string" ? obj : obj.url);
        }

        if (args) {
            for (let key in args) {
                url = url.replace(":" + key, args[key]);
            }
        }

        return url;
    }
}

// Colors
let envTheme = {
    dark: false,
    themes: {
        light: {
            primary: "#00af20",
            secondary: "#ececec",
            accent: "#ff5800",
            //accent: "#FFAB00",
            error: "#ff5800",
            warning: "#FFAB00",
            info: "#FFEA00",
            success: "#759e00"
        }
    }
};

if (appEnv !== "production") {
    envTheme = {
        dark: false,
        themes: {
            light: {
                primary: "#FB8C00",
                secondary: "#ececec",
                accent: "#ff5800",
                //accent: "#FFAB00",
                error: "#ff5800",
                warning: "#FFAB00",
                info: "#FFEA00",
                success: "#759e00"
            }
        }
    };
}

export const theme = envTheme;

// Languages
export const defaultLanguageIso = "de";
export const availableLanguages = [
    {
        iso: "de",
        label: "deutsch"
    },
    {
        iso: "fr",
        label: "français"
    },
    {
        iso: "gb",
        label: "english"
    },
    {
        iso: "it",
        label: "italiano"
    }
];
export const mandatoryLanguages = ["de", "fr"];

// Mediavalet API URL
export const mediavaletAuthApi = Object.assign(new URL(mediavaletAuthUrl), {
    url: "",
    token: {
        url: "token"
    }
});

// Mediavalet Auth URL TODO: will soon be the same as the their API-Url
export const mediavaletApi = Object.assign(new URL(mediavaletApiUrl), {
    url: "",
    assets: {
        url: "assets",
        search: "search"
    }
});

export const tenants = [
    {
        id: 1,
        abbr: "ch"
    },
    {
        id: 2,
        abbr: "de"
    },
    {
        id: 3,
        abbr: "sg"
    }
];

// Hauert Middleware API Route mapping
export const api = Object.assign(new URL(apiUrl), {
    url: "",

    // auth
    login: {
        url: "login",
        refresh: "refresh"
    },
    logout: "logout",
    password: {
        url: "password",
        forgot: "email",
        reset: "reset"
    },

    // user
    me: "user",
    users: {
        url: "users",

        list: ":tenant",
        update: ":id",
        activate: ":id/activate",
        single: ":id",
        restore: ":id/restore",
        settings: "settings",
        delete: ""
    },
    profile: {
        url: "profile"
    },

    // products
    products: {
        url: "products",

        list: "list?include=tenant,brand",
        export: "export/full?include=",
        customExport: "export/custom?include=",
        printDataExport: "export/print-data?include=",
        datasheetExport: "export/datasheet?include=",
        search: "search",
        detail: ":id",
        revert: ":id/revert/:version",
        fetchDraft: ":id/draft",
        deleteDraft: ":id/draft",
        lockDraft: ":id/lock",
        printLabels: ":id/print-labels",
        previewPrintLabels: ":id/print-label-preview",
        downloadPrintLabel: ":id/dl-print-label/:printLabelId",
        getPrintLabel: ":id/print-labels/:printLabelId",
        deployDraft: ":id/deploy",
        metadata: ":id/metadata",
        versions: ":id/versions",
        changes: ":id/changes/:version",
        lastChanges: ":id/last-changes",
        version: ":id/version/:version",
        update: ":id",
        copy: ":id/copy",
        syncStatus: "sync/status/:tenant",
        syncProducts: "sync/start",
        checkEditable: ":id/check-editable",
        query: "all/search?s=:term",
        queryWithinTenant: ":tenant/search?s=:term",
        bulkUpdate: "bulk-update"
    },
    // master-data
    masterData: {
        url: "master-data",

        list: "",
        listAll: "all/:tenant/:scope",
        update: ":entity/:id",
        createItem: ":entity",
        delete: ":entity/:id"
    }
});
