<template>
    <v-data-table
        v-if="renderIt"
        :headers="headers"
        :items="items"
        dense
        :disable-pagination="false"
        :items-per-page="-1"
        :hide-default-footer="true"
        :class="id"
    >
        <template #top>
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <slot name="title"></slot>
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <slot name="topMiddle"></slot>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="768px">
                    <template #activator="{ on }">
                        <v-btn
                            :disabled="readonly"
                            outlined
                            x-small
                            color="primary"
                            v-on="on"
                        >
                            <v-icon small>add</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ title }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-select
                                            v-model="editedItem.type"
                                            autofocus
                                            :items="itemTypes"
                                            :label="$t('Type')"
                                            clearable
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-text-field
                                            v-model="editedItem.value"
                                            single-line
                                            :label="$t('Value')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-select
                                            v-model="editedItem.unit"
                                            :items="[
                                                '%',
                                                'g/l',
                                                'ml/l',
                                                'mg/l'
                                            ]"
                                            :label="$t('Unit')"
                                            clearable
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-combobox
                                            v-model="editedItem.element"
                                            :items="[
                                                'Fe',
                                                'K₂O',
                                                'Mg',
                                                'N',
                                                'NS',
                                                'NA',
                                                'P₂O₅',
                                                'PS',
                                                'S',
                                                'SiO₂',
                                            ]"
                                            :label="$t('Element')"
                                            clearable
                                        ></v-combobox>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="12">
                                        <v-text-field
                                            v-model="editedItem.text"
                                            :label="$t('Text')"
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions v-if="!readonly">
                            <v-spacer></v-spacer>
                            <v-btn color="error" text @click="close">{{
                                $t("Abbrechen")
                            }}</v-btn>
                            <v-btn color="primary" outlined @click="save">{{
                                $t("Speichern")
                            }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template #item.type="props">
            <span v-if="props.item.type === 'main'">{{ $t("Haupt") }}</span>
            <span v-else-if="props.item.type === 'description'">{{
                $t("Beschrieb")
            }}</span>
            <span v-else-if="props.item.type === 'long-term'">{{
                $t("Langzeit")
            }}</span>
            <span v-else>{{ $t("kein") }}</span>
        </template>
        <template #item.text="props">
            <v-edit-dialog>
                {{ props.item.text }}
                <template #input>
                    <v-text-field
                        v-model="props.item.text"
                        label="Text"
                        single-line
                        autofocus
                        disabled
                        @change="$emit('input', value)"
                    ></v-text-field>
                </template>
            </v-edit-dialog>
        </template>
        <template #item.action="{ item }">
            <v-btn icon :disabled="readonly" @click="editItem(item)">
                <v-icon small class="mr-2">edit</v-icon>
            </v-btn>

            <v-btn icon :disabled="readonly" @click="deleteItem(item)">
                <v-icon small>
                    delete
                </v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import Sortable from "sortablejs";
import { mapGetters } from "vuex";

export default {
    props: {
        language: {
            type: String,
            required: true
        },
        value: {
            type: [String, Object],
            default: ""
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        id: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            dialog: false,
            renderIt: true,
            content: this.value,
            itemTypes: [
                {
                    value: "description",
                    text: "Beschrieb"
                },
                {
                    value: "main",
                    text: "Haupt"
                },
                {
                    value: "long-term",
                    text: "Langzeit"
                }
            ],
            itemUnits: [
                {
                    value: "%",
                    text: "%"
                },
                {
                    value: "main",
                    text: "Haupt"
                }
            ],
            headers: [
                {
                    text: this.$t("Type"),
                    value: "type",
                    sortable: false
                },
                {
                    text: this.$t("Zahl"),
                    value: "value",
                    sortable: false
                },
                {
                    text: this.$t("Unit"),
                    value: "unit",
                    sortable: false
                },
                {
                    text: this.$t("Element"),
                    value: "element",
                    sortable: false
                },
                {
                    text: this.$t("Text"),
                    value: "text",
                    align: "right",
                    sortable: false
                },
                {
                    text: this.$t("Action"),
                    value: "action",
                    align: "right",
                    sortable: false
                }
            ],
            editedItem: {
                type: "",
                value: "",
                unit: "",
                element: "",
                text: ""
            },
            defaultItem: {
                type: "",
                value: "",
                unit: "",
                element: "",
                text: ""
            }
        };
    },
    computed: {
        ...mapGetters({
            product: "products/productToEdit"
        }),
        items() {
            return this.value[this.language];
        }, 
        title() {
            return this.$t("Nutrients") + " " + this.$t('add/edit');
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        language(val) {
            if(`${val}` in this.value){
                ;
            } else {
                const newVal = {...this.value, [val]: []}
                this.$emit("input", newVal);
            }
        },
        value: {
            handler() {
                this.forceRerender();
            },
            deep: true
        }
    },
    mounted() {
        this.attachTableHook();
    },
    methods: {
        close() {
            this.attachTableHook();
            this.editedItem = { ...this.defaultItem };
            this.dialog = false;
        },
        deleteItem(item) {
            const index = this.value[this.language].indexOf(item);
            const copyVal = { ...this.value };
            confirm(this.$t("Are you sure you want to delete this item?")) &&
                copyVal[this.language].splice(index, 1);

            this.$emit("input", copyVal);
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderIt = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderIt = true;
                this.attachTableHook();
            });
        },
        attachTableHook() {
            this.$nextTick(() => {
                //let tables = document.querySelectorAll(".v-data-table tbody");
                let tables = document.querySelectorAll(".v-data-table." + this.id + " tbody");
                const _self = this;

                tables.forEach(table => {
                    Sortable.create(table, {
                        onEnd({ newIndex, oldIndex }) {
                            const copyVal = { ..._self.value };
                            const rowSelected = copyVal[_self.language].splice(
                                oldIndex,
                                1
                            )[0];
                            copyVal[_self.language].splice(
                                newIndex,
                                0,
                                rowSelected
                            );
                            _self.forceRerender();
                            let cleanValue = _self.cleanup(copyVal);
                            _self.$emit("input", cleanValue);
                        }
                    });
                });
            });
        },
        save() {
            let copyVal = { ...this.value };
            if (this.editedIndex > -1) {
                Object.assign(
                    copyVal[this.language][this.editedIndex],
                    this.editedItem
                );
                this.editedIndex = -1;
            } else {
                if (!Array.isArray(this.value[this.language])) {
                    const newItem = {
                        [this.language]: [this.editedItem]
                    };
                    copyVal = { ...this.value, ...newItem };
                } else {
                    copyVal[this.language].push(this.editedItem);
                }
            }
            const cleanValue = this.cleanup(copyVal);
            this.$emit("input", cleanValue);
            this.close();
        },
        cleanup(value) {
            value.fr &&
                value.fr.forEach(item => {
                    delete item.children;
                });
            value.de &&
                value.de.forEach(item => {
                    delete item.children;
                });
            return value;
        },
        editItem(item) {
            this.editedIndex = this.value[this.language].indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        textChanged(node, newVal) {
            let filteredText = newVal.replace("g/m2", "g/m²");
            filteredText = filteredText.replace("m2", "m²");
            filteredText = filteredText.replace("P2O5", "P₂O₅");
            filteredText = filteredText.replace("SiO2", "SiO₂");
            filteredText = filteredText.replace("K2O", "K₂O");
            filteredText = filteredText.replace("m3", "m³");
            node.data.text = filteredText;
        }
    }
};
</script>
