<template>
  <v-row justify="center">
      <v-dialog :value="open" persistent max-width="90%" height="90%">
          <v-card>
              <v-card-title>
                  <span class="text-h6">{{ $t("Preview print label") }}</span>
              </v-card-title>
              <v-card-text>
                  <v-container class="pdf-container">
                      <embed :src="previewPdf" width="100%" height="100%" type="application/pdf">
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                      {{ $t("Close") }}
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: {
      open: {
          type: Boolean,
          required: true
      },
      previewPdf: {
          type: String,
          required: true
      }
  },
  data() {
      return {
          reason: null,
          loading: false
      };
  },

  methods: {
      close() {
          this.$emit("close");
      },
  }
};
</script>

<style scoped>
  .pdf-container {
        height: 75vh;
  }
</style>
