<template>
    <v-expansion-panels accordion flat class="translations-panels elevation-0 px-0">
        <v-expansion-panel>
            <v-expansion-panel-header class="elevation-0 px-0">
                <v-textarea
                    v-if="multiline"
                    v-model="iValue[iLanguage]"
                    :label="label"
                    :auto-grow="autogrow"
                    :maxlength="maxlength"
                    :rules="rules"
                    :readonly="readonly"
                    :disabled="readonly"
                    :counter="counter"
                    :placeholder="placeholder"
                    :validate-on-blur="true"
                    @focus.stop.prevent
                    @focus="inputFocussed"
                    @input="updateTranslation"
                >
                    <slot slot="prepend" name="prepend"></slot>
                    <div
                        slot="append"
                        small
                        :class="'flag-icon flag-icon-' + iLanguage"
                    >
                        <v-select
                            v-if="changeableLanguage"
                            v-model="iLanguage"
                            :items="['de', 'fr', 'it', 'en']"
                            item-text="name"
                            item-value="iso" 
                            :label="false"
                            hide-details
                        />
                </div>
                </v-textarea>
                <v-text-field
                    v-if="!multiline"
                    v-model="iValue[iLanguage]"
                    :label="label"
                    :maxlength="maxlength"
                    :rules="rules"
                    :counter="counter"
                    :readonly="readonly"
                    :disabled="readonly"
                    :placeholder="placeholder"
                    :validate-on-blur="true"
                    @focus="inputFocussed"
                    @click.stop.prevent
                    @input="updateTranslation"
                >
                    <slot slot="prepend" name="prepend"></slot>
                    <div
                        v-if="changeableLanguage"
                        slot="append"
                        small
                    >
                        <v-select
                            v-model="iLanguage"
                            :items="['de', 'fr', 'it']"
                            item-text="name"
                            item-value="iso"
                            label=""
                            hide-details
                        >
                        <template #selection="{ item }">
                            <v-icon small :class="`flag-icon flag-icon-${item} mr-2`" />{{ $t(`language_${item}`) }}
                        </template>
                        <template #item="{ item }">
                            <v-icon small :class="`flag-icon flag-icon-${item} mr-2`" />{{ $t(`language_${item}`) }}
                        </template>
                    </v-select>
                    </div>
                    <div
                        v-if="!changeableLanguage"
                        slot="append-outer"
                        small
                        :class="'flag-icon flag-icon-' + iLanguage"
                    >
                    </div>
                </v-text-field>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(item, iso) in value" :key="iso">
                        <v-expansion-panel-header>
                            <span
                                slot="actions"
                                :class="'flag-icon flag-icon-' + iso"
                            ></span>
                            <div v-if="!hasTranslation" class="font-italic">
                                {{ $t("No translation available") }}
                            </div>
                            <template v-else>{{ item }}</template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card>
                                <v-card-text class="grey lighten-3">
                                    <VueMarkdown :source="item" />
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
    components: {
        VueMarkdown
    },
    props: {
        autogrow: {
            type: Boolean,
            default: false
        },

        changeableLanguage: {
            type: Boolean,
            default: false
        },

        value: {
            type: [Array, Object],
            default: () => {
                return {};
            }
        },

        rules: {
            type: Array,
            default: () => {
                return [];
            }
        },

        multiline: {
            type: Boolean,
            default: false
        },

        defaultLanguage: {
            type: String,
            default: "de"
        },

        label: {
            type: String,
            default: ""
        },

        maxlength: {
            type: Number,
            default: -1
        },

        counter: {
            type: [Number, Boolean],
            default: false
        },

        readonly: {
            type: Boolean,
            default: false
        },

        placeholder: {
            type: String,
            default: " "
        },

        valueOnEmptyFocus: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            iValue: this.value,
            iLanguage: this.defaultLanguage
        };
    },

    computed: {
        defaultLanguageValue: {
            get() {
                if (
                    this.value === undefined ||
                    this.value == null ||
                    Object.keys(this.value).length == 0
                ) {
                    return "";
                }
                return this.value[this.iLanguage];
            },
            set(value) {
                this.updateTranslation(value);
            }
        },

        hasTranslation() {
            if (
                this.value === undefined ||
                this.value == null ||
                Object.keys(this.value).length == 0
            ) {
                return "";
            }
            let dl = this.iLanguage;
            let r = false;
            Object.keys(this.value).forEach(function(key) {
                if (key !== dl) {
                    r = true;
                }
            });
            return r;
        }
    },

    watch: {
        value: {
            handler(newVal) {
                this.iValue = newVal;
            },
            deep: true
        },

        defaultLanguage: {
            handler() {
                this.iValue = this.value;
                this.iLanguage = this.defaultLanguage;
            }
        }
    },

    methods: {
        inputFocussed() {
            if (
                !this.value[this.iLanguage] ||
                this.value[this.iLanguage].length < 1
            ) {
                this.updateTranslation(this.valueOnEmptyFocus);
                this.iValue[this.iLanguage] = this.valueOnEmptyFocus;
            }
        },

        updateTranslation(payload) {
            payload = payload.replace("g/m2", "g/m²");
            payload = payload.replace("m2", "m²");
            payload = payload.replace("P2O5", "P₂O₅");
            payload = payload.replace("SiO2", "SiO₂");
            payload = payload.replace("K2O", "K₂O");
            payload = payload.replace("m3", "m³");
            let newValue = this.value;
            if (
                newValue === undefined ||
                newValue == null ||
                Object.keys(newValue).length == 0
            ) {
                newValue = {};
            }
            newValue = {
                ...newValue,
                [`${this.iLanguage}`]: payload
            };
            this.$emit("input", newValue);
        }
    }
};
</script>

<style>
.v-expansion-panel__header {
    padding: 0;
}
</style>
