export default {
    Action: "Aktion",
    "App Name": "Hauert Middleware",
    Password: "Passwort",
    "Confirm New Password": "Passwort bestätigen",
    Products: "Produkte",
    Botany: "Botanik",
    Basic: "Basis",
    Logistics: "Logistik",
    "Texts + Media": "Texte und Medien",
    Attributes: "Eigenschaften",
    "Printdata export": "Druckdaten Export",
    Declarations: "Inhaltsstoffe",
    Advanced: "Erweitert",
    "Basic information": "Basis Informationen",
    Tenant: "Mandant",
    "Product no": "Produkt Nr.",
    Brand: "Marke",
    Fields: "Felder",
    "All fields": "Alle Felder",
    "Product description": "Beschreibung",
    "Extended description": "Beschreibung erweitert",
    "Product group": "Produktgruppe",
    "Datasheet export": "Datenblatt Export",
    "Product group code": "Produktgruppencode",
    "Select at least one language": "Mindestens eine Sprache wählen",
    "Registry numbers": "Registrierungsnummern",
    Languages: "Sprachen",
    Language: "Sprache",
    "H + P Statements": "H + P Sätze",
    "Gross Weight": "Bruttogewicht",
    "Bulk update products": "Produkte Serienupdate",
    "Net Weight": "Nettogewicht",
    "Weight of Pallet": "Gewicht / Palette",
    Units: "Stück",
    Selection: "Auswahl",
    "fields selected": "Felder ausgewählt",
    "Quantity of pallet": "Anzahl pro Palette",
    Inventory: "Lagerbestand",
    "Bulk update": "Serienupdate",
    "Inventory per piece": "Lagerbestand in Stück",
    "Quantity of package": "Anzahl pro Paket",
    "Minimum order quantitiy": "Mindesbestellmenge",
    "EAN for repackaging": "EAN f. Umverpackungen",
    "Customs tariff number": "Zolltarifnummern",
    Weights: "Gewichte",
    Quantities: "Mengen",
    Pricing: "Preis",
    "Packaging size": "Verpackugnsgröße",
    "Unit price": "Richtpreis",
    "Base price": "Grundpreis",
    "Base price per kilo": "Grundpreis pro Kilogramm bzw. Liter",
    Numbers: "Nummern",
    "Distribution and Transport": "Distribution und Transport",
    Distributor: "Distribuent",
    "Transport regulations": "Transportvorschriften",
    Texts: "Texte",
    "Labeltext 1": "Etikettentext 1",
    "Labeltext 2": "Etikettentext 2",
    "Description Text": "Beschreibung Text",
    "Description short": "Kurzbeschreibung",
    "Description long": "Beschreibung lang",
    "Marketing text for key accounts": "Marketingtext für Key Accounts",
    Descriptions: "Beschreibungen",
    Marketing: "Marketing",
    "Features and dosage": "Merkmale und Dosierung",
    Application: "Anwendung",
    Features: "Attribute",
    Dosage: "Dosierung",
    "Dosage simple": "Dosierung einfach",
    "Take note": "Zu beachten",
    "Storage notes": "Lagerungshinweise",
    "Disposal notes": "Entsorgung",
    shoppable: "Shoppable",
    "Is new": "Neu",
    "Show calculator": "Düngerrechner",
    "Emergency information": "Notfallauskunft",
    Media: "Medien",
    Item: "Artikel",
    Security: "Sicherheit",
    "Product translation": "Übersetzung Produkt",
    "Item translation": "Übersetzung",
    "EC-fertilizer": "EG-Düngermittel",
    "FiBL listed": "FiBL Betriebsmittelliste gelistet",
    "Chemical type": "Düngertyp/Pflanzenschutzmittelkategorie",
    Purpose: "Geeignet für Verwendungszeck",
    Seasons: "Saisons",
    Save: "Speichern",
    Cancel: "Abbrechen",
    "Effect duration": "Wirkdauer",
    "Plant group": "Pflanzengruppe",
    Nutrients: "Nährstoffe",
    "Base substances": "Ausgangsstoffe",
    "Minor components": "Nebenbestandteile",
    "New main nutrient": "Neuer Hauptnährstoff",
    "New main base substance": "Neuer Hauptausgangstoff",
    "New main minor component": "Neuer Hauptnebenbestandteil",
    Relation: "Verbindung",
    Relations: "Verbindungen",
    Prices: "Preise",
    Brands: "Marken",
    Tasks: "Ratgeber-Tätigkeit",
    "Application sites": "Anwendungsorte",
    "Application scopes": "Tätigkeit / Ratgeber",
    "H+P Statements": "H+P Sätze",
    "Export web": "Export web",
    "Hazard symbols": "Gefahrensymbole",
    "New item": "Neuer Eintrag",
    "Task areas": "Tätigkeitsbereiche",
    "Plant groups": "Pflanzengruppen",
    "Product groups": "Produktgruppen",
    "product-group": "Produktgruppen",
    "Product line": "Produktlinie",
    "Product lines": "Produktlinien",
    "Product types": "Produktearten",
    "in en": "auf englisch",
    "in de": "auf deutsch",
    "in fr": "auf französisch",
    Usage: "Tätigkeit / Produkteseiten",
    "Organic labels": "Bio Labels",
    Tenants: "Mandanten",
    task: "Ratgeber-Tätigkeiten",
    "application-site": "Anwendungsorte",
    brand: "Marken",
    attribute: "Eigenschaften",
    "effect-duration": "Wirkdauer",
    tenant: "Mandant",
    characteristic: "Eigenschaften Zusammensetzung",
    "chemical-type": "Düngertyp / Pflanzenschutzmittelkategorie",
    "task-area": "Tätigkeitsbereiche",
    "organic-label": "Bio Labels",
    "product-line": "Produktlinien",
    "product-type": "Produktarten",
    season: "Saisons",
    usage: "Tätigkeit / Produktseiten",
    "plant-group": "Pflanzengruppen",
    "Art.No.": "Produktnur.",
    "The form field is required": "Das Feld '{field}' ist erfolderlich",
    "Forgot password?": "Passwort vergessen?",
    "Do you really want to delete this entity":
        "<b>'{name}'</b> wirklich löschen?",
    "Delete item": "<b>{entity}</b>: Löschen",
    "Hazard symbol": "Gefahrensymbol",
    "hazard-symbol": "Gefahrensymbole",
    "hp-statement": "H+P Satz",
    purpose: "Verwendungszwecke",
    Purposes: "Verwendungszwecke",
    Characteristics: "Eigenschaften Zusammensetzung",
    "product-group-code": "Produktruppen Codes",
    "Product group codes": "Produktruppen Codes",
    "storage-note": "Lagerungshinweise",
    "storage-notes": "Lagerungshinweise",
    "emergency-information": "Notfallauskünfte",
    "disposal-note": "Entsorgung",
    "disposal-notes": "Entsorgung",
    plant: "Pflanzen",
    plants: "Pflanzen",
    Plants: "Pflanzen",
    Hints: "Hinweise",
    Website: "Webseite",
    "application-scope": "Tätigkeit/Produktseiten/Anwendung",
    "Hazard statements": "H-Sätze",
    "hazard-statement": "H-Sätze",
    "Precautionary statements": "P-Sätze",
    "precautionary-statement": "P-Sätze",
    "Chemical types": "Düngertyp/Pflanzenschutzmittelkategorie",
    "chemical-types": "Düngertyp/Pflanzenschutzmittelkategorie",
    Distributions: "Inverkehrbringer",
    distribution: "Inverkehrbringer",
    Distribution: "Inverkehrbringer",
    "Master data": "Stammdaten",
    Profile: "Profil",
    "Dosage min": "Dosierung min",
    "Dosage max": "Dosierung max",
    "Newly imported": "neu Importierte",
    Unpublished: "nur Unveröffentlichte",
    Published: "nur Veröffentlichte",
    "Newly unpublished": "neu Unveröffentliche",
    Labels: "Labels",
    Stocks: "Lagerbestände",
    tenant_ch: "CH",
    tenant_de: "DE",
    "Imported at": "Importiert",
    "Published at": "Veröffentlicht",
    "Do you really want to leave? You have unsaved changes!":
        "Ungespeicherte Änderungen - Wirklich verlassen?",
    OK: "OK",
    "E-Mail": "E-Mail",
    Yes: "Ja",
    No: "Nein",
    "prices.Unit price": "Preis",
    "No prices for this product available":
        "Keine Preise für dieses Produkt verfügbar",
    "VAT included": "MWSt. inklusive",
    Currency: "Währung",
    "Minimum quantity": "Mindestmenge",
    "Unit of measure code": "Einheitencode",
    "Ending date": "Enddatum",
    "Starting date": "Startdatum",
    "Sales type": "Verkaufsart",
    "Sales code": "Verkaufscode",
    "Start typing to search products.":
        "Produktsuche über Eingabe beginnen ...",
    Vats: "Mehrwehrtsteuersätze",
    vat: "Mehrwehrtsteuersätze",
    "Switch tenant": "Zu Mandant wechseln",
    Close: "Schließen",
    Switch: "Wechseln",
    Usermanagement: "Benutzerverwaltung",
    "Create account": "Neuer Zugang",
    "New account": "Neuer Zugang",
    "Edit account": "Zugang bearbeiten",
    "No users created": "Keine Accounts angelegt",
    "Your Profile": "Profildaten",
    "Are you sure you want to delete this item?": "Wirklich löschen?",
    Translation: "Übersetzung",
    Deploy: "Aktivieren",
    "Grain size": "Korngrösse",
    diff_product_number: "Produkt Nr.",
    diff_packaging_size_id: "Packaging Size ID",
    diff_tenant_id: "Mandant",
    diff_brand_id: "Marke",
    diff_product_group_id: "Produktgruppe",
    diff_product_group_code_id: "Produktgruppen-Code",
    diff_gtin: "GTIN",
    diff_name: "Beschreibung",
    diff_extended_name: "Beschreibung erweitert",
    diff_registry_numbers: "Registrierungsnummern",
    diff_slug: "Slug",
    diff_image: "Image",
    diff_logo: "Logo",
    diff_safety_data_sheet: "Datasheet",
    diff_qr_code_url: "QR-Code-URL",
    diff_guiding_price: "Richtpreis",
    diff_unit_price: "Einzelpreis",
    diff_vat: "MwSt",
    diff_vat_id: "MwSt-ID",
    diff_weight_gross: "Bruttogewicht",
    diff_weight_net: "Nettogewicht",
    diff_weight_of_pallet: "Gewicht Palette",
    diff_dimensions: "Dimensions",
    diff_quantity_per_package: "Anzahl p. Paket",
    diff_quantity_of_pallet: "Anzahl p. Palette",
    diff_minimum_order_quantity: "Mindestbestellmenge",
    diff_inventory: "Inventar",
    diff_inventory_per_piece: "Lagerbestand in Stück",
    diff_customs_tariff_number: "Zolltarifnummern",
    diff_ean_for_repackaging: "EAN",
    diff_transport_regulations: "Transportvorschriften",
    diff_description_short: "Kurzbeschreibung",
    diff_features_text: "Attribute",
    diff_description_long: "Beschreibung Text",
    diff_application_text: "Anwendung",
    diff_dosage_text: "Dosierung",
    diff_dosage_exact: "Dosierung einfach",
    diff_dosage_unit: "Dosierung unit",
    diff_dosage_min: "Dosierung min",
    diff_dosage_max: "Dosierung max",
    diff_take_note: "Zu beachten",
    diff_storage_note_id: "Lagerung",
    diff_disposal_note_id: "Entsorgung",
    diff_emergency_information_id: "Notfallauskunft",
    diff_special_text: "Spezialtext",
    diff_marketing_text: "Marketingtext",
    diff_label_1_text: "Etikettentext 1",
    diff_label_2_text: "Etikettentext 2",
    diff_chemical_type_id: "Düngertyp",
    diff_season_ids: "Saisons",
    diff_effect_duration_id: "Wirkdauer",
    diff_product_line_id: "Produktlinie",
    diff_grain_size: "Körnergröße",
    diff_distribution_id: "Inverkehrbringer",
    diff_is_ec_fertilizer: "EG-Düngermittel",
    diff_is_fibl_listed: "FiBL",
    diff_nutrients: "Nährstoffe",
    diff_base_substances: "Ausgangsstoffe",
    diff_minor_components: "Nebenbestandteile",
    diff_published_at: "Veröffentlicht",
    "Label templates": "Etiketten Vorlagen",
    "label-template": "Etiketten Vorlagen",
    "ingredient-legend-item": "Legende Inhaltsstoffe",
    "Ingredient Legend Items": "Legende Inhaltsstoffe",
    "ingredient": "Inhaltsstoffe",
    "Ingredients": "Inhaltsstoffe",
    "Print labels": "Etikettendruck",
    "Product identification Navision": "Produktidentifikation Navision",
    "Existing labels": "Vorhandene Etiketten",
    "Template selection": "Auswahl der Vorlage",
    "language_fr": "französisch",
    "language_it": "italienisch",
    "language_en": "englisch",
    "language_de": "deutsch",
    "Declaration details": "Deklarationsinformationen",
    "Item number": "Artikelnummer",
    "Item number and Lot": "Artikel- und Chargennummer",
    "Lot number": "Chargennummer",
    "Product information": "Produktinformationen",
    "Fertilizer categories": "Düngerkategorien",
    "fertilizer-category": "Düngerkategorien",
    "Product name": "Produktname",
    "Chemical Type on the label": "Düngertyp",
    "Fertilizer category": "Düngerkategorie",
    "NPK information": "NPK-Angaben",
    "Applicaton and storage": "Anwendung und Lagerung",
    "Instructions": "Gebrauchsanweisung",
    "Storage conditions": "Lagerbedingungen",
    "Net weight": "Nettogewicht",
    "CE symbol": "CE-Grafik",
    "Show CE symbol": "CE-Grafik anzeigen",
    "CD graphic": "CD-Grafik",
    "Safety and environmental information": "Informationen zu Sicherheit und Umwelt",
    "Show information on label": "Informationen auf Etikett anzeigen",
    "UFI code": "UFI-Code",
    "Template": "Vorlage",
    "Created by": "Erstellt von",
    "Date": "Datum",
    "Print label has been synced successfully": "Etikette erfolgreich geladen",
    "add/edit": "hinzufügen/bearbeiten",
    "Preview print label": "Etikettvorschau",
    "Save draft": "Entwurf speichern",  
    "Save as new Version": "Als neue Version speichern",
    "Legend": "Legende",
    "Custom legend text": "Legenden Text",
};
