export default [
    ...applyRules(
        ["guest"],
        [
            {
                path: "",
                component: require("$comp/auth/AuthContainer").default,
                redirect: {
                    name: "login"
                },
                children: [
                    {
                        path: "/login",
                        name: "login",
                        component: require("$comp/auth/login/Login").default
                    },
                    {
                        path: "/password",
                        component: require("$comp/auth/password/PasswordContainer")
                            .default,
                        children: [
                            {
                                path: "",
                                name: "forgot",
                                component: require("$comp/auth/password/password-forgot/PasswordForgot")
                                    .default
                            },
                            {
                                path: "reset/:token",
                                name: "reset",
                                component: require("$comp/auth/password/password-reset/PasswordReset")
                                    .default
                            }
                        ]
                    }
                ]
            }
        ]
    ),
    ...applyRules(
        ["auth"],
        [
            {
                path: "",
                component: require("$comp/AppContainer").default,
                children: [
                    {
                        path: "",
                        name: "index",
                        component: require("$comp/dashboard/Dashboard").default
                    },
                    {
                        path: "products",
                        component: require("$comp/product/ProductContainer")
                            .default,
                        children: [
                            {
                                path: "",
                                name: "products",
                                component: require("$comp/product/list/ProductList")
                                    .default
                            },
                            {
                                path: "export",
                                name: "products-export",
                                component: require("$comp/product/export/ProductExports")
                                    .default
                            },
                            {
                                path: "sync",
                                name: "products-sync",
                                component: require("$comp/product/sync/ProductSync")
                                    .default
                            },
                            {
                                path: "bulk-update",
                                name: "products-bulk-update",
                                component: require("$comp/product/bulk-update/ProductBulkUpdate")
                                    .default
                            },
                            {
                                path: "view/:id",
                                name: "product-view",
                                component: require("$comp/product/edit/ProductEdit")
                                    .default,
                                props: {
                                    readonly: true,
                                    manageProduct: true
                                }
                            },
                            {
                                path: "view/:id/version/:version",
                                name: "product-view-version",
                                component: require("$comp/product/edit/ProductEdit")
                                    .default,
                                props: {
                                    readonly: true,
                                    manageProduct: false
                                }
                            },
                            {
                                path: "edit/:id",
                                name: "product-edit",
                                component: require("$comp/product/edit/ProductEdit")
                                    .default,
                                props: {
                                    readonly: false,
                                    manageProduct: false
                                }
                            },
                            {
                                path: "versions/:id",
                                name: "product-versions",
                                component: require("$comp/product/versions/VersionsContainer")
                                    .default
                            }
                        ]
                    },
                    {
                        path: "master-data",
                        name: "masterData",
                        redirect: {
                            name: "applicationSites"
                        },
                        component: require("$comp/master-data/MasterDataContainer")
                            .default,
                        children: [
                            {
                                path: "organic-labels",
                                name: "organicLabels",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "organic-label",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true,
                                    hasDescription: true,
                                    hasTranslatableFile: true
                                }
                            },
                            {
                                path: "brands",
                                name: "brands",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "brand",
                                    hasCode: true,
                                    hasName: "string",
                                    hasSectors: true,
                                    hasTenants: true,
                                    hasFile: true
                                }
                            },
                            {
                                path: "application-scopes",
                                name: "applicationScopes",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "application-scope",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "application-sites",
                                name: "applicationSites",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "application-site",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "characteristics",
                                name: "characteristics",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "characteristic",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "effect-durations",
                                name: "effectDurations",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "effect-duration",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "hazard-statements",
                                name: "hazardStatements",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "hazard-statement",
                                    hasCode: true,
                                    hasStatement: true,
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "precautionary-statements",
                                name: "precautionaryStatements",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "precautionary-statement",
                                    hasCode: true,
                                    hasStatement: true,
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "chemical-types",
                                name: "chemicalTypes",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "chemical-type",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "task-areas",
                                name: "taskAreas",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "task-area",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "product-lines",
                                name: "productLines",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "product-line",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true,
                                    hasBrand: true
                                }
                            },
                            {
                                path: "product-types",
                                name: "productTypes",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "product-type",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "product-groups",
                                name: "productGroups",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "product-group",
                                    hasCode: true,
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true,
                                    hasProductType: true
                                }
                            },
                            {
                                path: "product-group-codes",
                                name: "productGroupCodes",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "product-group-code",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "storage-notes",
                                name: "storageNotes",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "storage-note",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "disposal-notes",
                                name: "disposalNotes",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "disposal-note",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "emergency-information",
                                name: "emergencyInformation",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "emergency-information",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "seasons",
                                name: "seasons",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "season",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "tasks",
                                name: "tasks",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "task",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "hazard-symbols",
                                name: "hazardSymbols",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "hazard-symbol",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true,
                                    hasFile: true
                                }
                            },
                            {
                                path: "purpose",
                                name: "purpose",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "purpose",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "distributions",
                                name: "distributions",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "distribution",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true,
                                    hasTemplate: true,
                                }
                            },
                            {
                                path: "plants",
                                name: "plants",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "plant",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "plant-groups",
                                name: "plantGroups",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "plant-group",
                                    hasName: "object",
                                    hasSectors: true,
                                    hasTenants: true
                                }
                            },
                            {
                                path: "vats",
                                name: "vats",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "vat",
                                    hasCode: true,
                                    hasValue: true,
                                    hasTenant: true
                                }
                            },
                            {
                                path: "label-templates",
                                name: "labelTemplates",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "label-template",
                                    hasName: "string",
                                    hasTemplate: true,
                                    hasTenants: true,
                                    hasLanguage: true
                                }
                            },
                            {
                                path: "ingredients",
                                name: "ingredients",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "ingredient",
                                    hasName: "object",
                                    hasIngredientLegendItems: true,
                                }
                            },
                            {
                                path: "ingredient-legend-items",
                                name: "ingredientLegendItems",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "ingredient-legend-item",
                                    hasName: "object",
                                }
                            },
                            {
                                path: "fertilizer-categories",
                                name: "fertilizerCategories",
                                component: require("$comp/master-data/Items")
                                    .default,
                                props: {
                                    entity: "fertilizer-category",
                                    hasCode: true,
                                    hasTenants: true
                                }
                            },
                        ]
                    },
                    {
                        path: "profile",
                        component: require("$comp/profile/ProfileContainer")
                            .default,
                        children: [
                            {
                                path: "",
                                name: "profile",
                                component: require("$comp/profile/Profile")
                                    .default
                            },
                            {
                                path: "edit",
                                name: "profile-edit",
                                component: require("$comp/profile/edit/ProfileEdit")
                                    .default
                            }
                        ]
                    },
                    {
                        path: "accounts",
                        component: require("$comp/account/AccountContainer")
                            .default,
                        children: [
                            {
                                path: "",
                                name: "accounts",
                                component: require("$comp/account/List").default
                            },
                            {
                                path: "edit",
                                name: "profile-edit",
                                component: require("$comp/profile/edit/ProfileEdit")
                                    .default
                            }
                        ]
                    }
                ]
            }
        ]
    ),
    {
        path: "*",
        redirect: {
            name: "index"
        }
    }
];

function applyRules(rules, routes) {
    for (let i in routes) {
        routes[i].meta = routes[i].meta || {};

        if (!routes[i].meta.rules) {
            routes[i].meta.rules = [];
        }
        routes[i].meta.rules.unshift(...rules);

        if (routes[i].children) {
            routes[i].children = applyRules(rules, routes[i].children);
        }
    }

    return routes;
}
