<template>
    <v-snackbar
        v-model="snackbar.visible"
        :timeout="snackbar.timeout"
        :multi-line="snackbar.multiline === true"
        :absolute="snackbar.absolute"
        :bottom="snackbar.bottom"
        :color="snackbar.color"
        :left="snackbar.left"
        :right="snackbar.right"
        :top="snackbar.top"
        :vertical="snackbar.vertical"
    >
        <div>{{ snackbar.text }}

            <v-btn class="ml-2 float-right" text dark @click="closeSnackbar"><v-icon >mdi-window-close</v-icon></v-btn>
        </div>
        
    </v-snackbar>
</template>

<script>
import { mapActions } from "vuex";

export default {
    computed: {
        snackbar() {
            return this.$store.state.ui.snackbar;
        }
    },

    methods: {
        ...mapActions({
            closeSnackbar: "ui/closeSnackbar"
        })
    }
};
</script>
