<template>
    <v-text-field
        v-if="!multiline"
        v-model="editValue"
        :rules="rules"
        :label="label"
        :counter="counter"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :multi-line="multiline"
        :autogrow="autogrow"
        :prepend-inner-icon="editing ? null : 'bookmark'"
        :append-icon="editing ? 'mdi-sync' : 'mdi-pencil'"
        :readonly="!editing"
        @click:append="clickAppend"
        @input="$emit('input', editValue)"
    >
    </v-text-field>
    <v-textarea
        v-else
        v-model="editValue"
        :rules="rules"
        :label="label"
        :counter="counter"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :multi-line="multiline"
        :autogrow="autogrow"
        :prepend-inner-icon="editing ? null : 'bookmark'"
        :append-icon="editing ? 'mdi-sync' : 'mdi-pencil'"
        :readonly="!editing"
        @click:append="clickAppend"
        @input="$emit('input', editValue)"
    >
    </v-textarea>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        defaultValue: {
            type: String,
            required: ""
        },
        value: {
            type: [String, Object],
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        readonly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        multiline: {
            type: Boolean,
            default: false
        },
        autogrow: {
            type: Boolean,
            default: false
        },
        counter: {
            type: [Number, Boolean],
            default: false
        },
        rules: {
            type: Array,
            default: () => {
                return [];
            }
        },
        maxlength: {
            type: Number,
            default: -1
        },
    },

    data() {
        return {
            editing: false,
            editValue: this.value
        };
    },

    computed: {
        ...mapGetters({
            product: "products/productToEdit"
        })
    },

    
    methods: {
        clickAppend() {
            this.editing = !this.editing;
            if (!this.editing) {
                this.editValue = this.defaultValue;
                this.$emit('input', this.editValue);
            }
            
        },
        
    }
};
</script>
