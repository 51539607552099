var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"product-edit",attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","sm12":"","xs12":""}},[_c('v-layout',{staticClass:"mx-0",attrs:{"justify-center":""}},[_c('v-breadcrumbs',{staticClass:"pl-1",attrs:{"items":_vm.crumbs}},[_c('v-icon',{attrs:{"slot":"divider"},slot:"divider"},[_vm._v("chevron_right")])],1),_c('v-spacer'),_c('v-spacer'),_c('v-flex',{staticClass:"mx-0 px-0 text-end",attrs:{"xs2":""}},[_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"grey darken-1"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[(!_vm.readonly)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("Deploy"))+" ")]),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":_vm.doDeploy}},[_c('v-icon',[_vm._v("mdi-check-decagram")])],1)],1)],1):_vm._e(),(!_vm.readonly)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" Kopieren ")]),_c('v-list-item-action',[_c('productFieldsSync',{attrs:{"readonly":_vm.readonly,"selected-language":_vm.productLanguage}})],1)],1):_vm._e(),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Informationen ")]),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){_vm.openMeta = true}}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("Translation"))+" ")]),_c('v-list-item-action',[_c('v-select',{staticClass:"lang-select mr-2",attrs:{"items":_vm.product.tenant &&
                                                _vm.product.tenant.settings
                                                    .product_validation
                                                    .available_languages,"item-text":"label","item-value":"iso"},model:{value:(_vm.productLanguage),callback:function ($$v) {_vm.productLanguage=$$v},expression:"productLanguage"}})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"elevation-1"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"accent"}}),_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.key},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])})],2),_c('v-card',{staticClass:"elevation-0",attrs:{"px0":""}},[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item.key},[(Object.keys(_vm.product).length > 0)?_c(item.key,{tag:"component",attrs:{"language":_vm.productLanguage,"product":_vm.product,"readonly":_vm.readonly,"manage-product":_vm.manageProduct,"fallback-language":_vm.fallbackLanguage},on:{"updateProduct":_vm.updateProduct}}):_vm._e()],1)}),1),_c('v-divider'),_c('v-card-actions',[(_vm.product.created_at)?_c('span',{staticClass:"font-italic grey--text"},[_vm._v(" "+_vm._s(_vm.$t("Imported at"))+": "+_vm._s(_vm.moment(_vm.product.created_at.date).format( "DD.MM.YYYY" ))+" ")]):_vm._e(),(
                                _vm.product.created_at && _vm.product.published_at
                            )?_c('span',{staticClass:"grey--text lighten-2"},[_vm._v(" | ")]):_vm._e(),(_vm.product.published_at)?_c('span',{staticClass:"font-italic grey--text primary--text lighten-3"},[_vm._v(" "+_vm._s(_vm.$t("Published at"))+": "+_vm._s(_vm.moment(_vm.product.published_at.date).format( "DD.MM.YYYY" ))+" ")]):_vm._e(),_c('v-spacer'),(_vm.tab !== 9)?_c('v-btn',{attrs:{"loading":_vm.saving,"color":"success darken-3","text":"","disabled":!_vm.manageProduct && _vm.readonly},on:{"click":_vm.storeProduct}},[_vm._v(_vm._s(_vm.$t("Save")))]):_vm._e(),(_vm.tab !== 9)?_c('v-btn',{attrs:{"active-class":"","color":"error darken-1","text":""},on:{"click":_vm.cancelEdit}},[_vm._v(_vm._s(_vm.$t("Cancel")))]):_vm._e()],1)],1)],1)],1)],1),_c('metadata',{attrs:{"product":_vm.product,"readonly":_vm.readonly,"open":_vm.openMeta},on:{"close":function($event){_vm.openMeta = false},"updateProduct":_vm.updateProduct}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }