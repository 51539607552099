<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template #activator="{ on }">
            <v-btn color="primary" dark class="mb-2" fab small v-on="on">
                <v-icon>add</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <v-icon class="mr-2 primary--text">add_circle_outline</v-icon>
                <h3 class="font-weight-thin">
                    {{ $t("New item") }}: {{ $t(entity) }}
                </h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form text @submit.prevent="submit">
                    <v-container grid-list-xs py-0 px-1>
                        <v-flex v-if="hasCode" xs12>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="newItem.code"
                                    :label="$t('Code')"
                                    autocomplete="nope"
                                ></v-text-field>
                            </v-flex>
                        </v-flex>
                        <v-flex v-if="hasValue" xs12>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="newItem.value"
                                    :label="$t('Value')"
                                    autocomplete="nope"
                                ></v-text-field>
                            </v-flex>
                        </v-flex>
                        <v-layout wrap>
                            <v-flex
                                v-if="hasStatement && newItem.statement"
                                xs12
                            >
                                <v-flex xs12>
                                    <v-subheader class="pl-0">
                                        {{ $t("Bezeichnung") }}
                                    </v-subheader>
                                </v-flex>
                                <v-flex
                                    v-for="lang in languages"
                                    :key="lang.iso"
                                    xs12
                                >
                                    <v-text-field
                                        v-if="newItem.statement"
                                        v-model="newItem.statement[lang.iso]"
                                        :label="$t('Statement')"
                                        :prepend-icon="
                                            $vuetify.icons['flag_' + lang.iso]
                                        "
                                        :placeholder="$t('in ' + lang.iso)"
                                        autocomplete="nope"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            <v-flex
                                v-if="hasDescription && newItem.description"
                                xs12
                            >
                                <v-flex xs12>
                                    <v-subheader class="pl-0">
                                        {{ $t("Beschreibung") }}
                                    </v-subheader>
                                </v-flex>
                                <v-flex
                                    v-for="lang in languages"
                                    :key="lang.iso"
                                    xs12
                                >
                                    <v-text-field
                                        v-model="newItem.description[lang.iso]"
                                        :label="$t('Description')"
                                        :prepend-icon="
                                            $vuetify.icons['flag_' + lang.iso]
                                        "
                                        :placeholder="$t('in ' + lang.iso)"
                                        autocomplete="nope"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            <v-flex
                                v-if="newItem.name && hasName === 'object'"
                                xs12
                            >
                                <v-flex xs12>
                                    <v-subheader class="pl-0">
                                        {{ $t("Bezeichnung") }}
                                    </v-subheader>
                                </v-flex>
                                <v-flex
                                    v-for="lang in languages"
                                    :key="lang.iso"
                                    xs12
                                >
                                    <v-text-field
                                        v-model="newItem.name[lang.iso]"
                                        :label="$t('Name')"
                                        :prepend-icon="
                                            $vuetify.icons['flag_' + lang.iso]
                                        "
                                        :placeholder="$t('in ' + lang.iso)"
                                        autocomplete="nope"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            <v-flex v-if="hasName === 'string'" xs12>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="newItem.name"
                                        :label="$t('Name')"
                                        autocomplete="nope"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>

                            <v-flex v-if="hasProductType" xs12>
                                <v-divider></v-divider>
                                <v-select
                                    v-model="newItem.product_type_id"
                                    :items="productTypes"
                                    item-value="id"
                                    :item-text="'name.' + selectedLanguage"
                                    attach
                                    chips
                                    :label="$t('Product type')"
                                >
                            </v-select>
                            </v-flex>

                            <v-flex v-if="hasBrand" xs12>
                                <v-divider></v-divider>
                                <v-select
                                    v-model="newItem.brand_id"
                                    :items="brands"
                                    item-value="id"
                                    item-text="name"
                                    attach
                                    chips
                                    :label="$t('Brand')"
                                ></v-select>
                            </v-flex>

                            <v-flex v-if="hasSectors" xs12>
                                <v-divider></v-divider>
                                <v-item-group multiple>
                                    <v-subheader class="pl-0">
                                        {{ $t("Shops") }}
                                    </v-subheader>
                                    <v-layout wrap>
                                        <v-flex>
                                            <v-checkbox
                                                v-model="newItem.is_pro"
                                                :label="$t('Profi')"
                                            ></v-checkbox>
                                        </v-flex>
                                        <v-flex>
                                            <v-checkbox
                                                v-model="newItem.is_hobby"
                                                :label="$t('Hobby')"
                                            ></v-checkbox>
                                        </v-flex>
                                        <v-flex>
                                            <v-checkbox
                                                v-model="newItem.is_agric"
                                                :label="$t('Agrar')"
                                            ></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-item-group>
                            </v-flex>

                            <v-flex v-if="hasLanguage" xs12>
                                <v-flex xs12>
                                    <v-select
                                        v-model="newItem.lang"
                                        :items="[{ iso: 'de', name: 'Deutsch' }, { iso: 'fr', name: 'Französisch' }, { iso: 'it', name: 'Italienisch' }, { iso: 'en', name: 'Englisch'}]"
                                        item-value="iso"
                                        item-text="name"
                                        attach
                                        chips
                                        :label="$t('Language')"></v-select>
                                </v-flex>
                            </v-flex>

                            <v-flex v-if="hasTemplate" xs12>
                                <v-flex xs12>
                                    <v-textarea
                                        v-model="newItem.template"
                                        :label="$t('Template')"
                                        autocomplete="nope"

                                    ></v-textarea>
                                </v-flex>
                            </v-flex>

                            <v-flex v-if="hasFile" xs12>
                                <media-selector
                                    v-model="newItem.file"
                                    :activator-label="$t('Select image')"
                                    :multiple="false"
                                ></media-selector>
                            </v-flex>

                            <v-flex v-if="hasIngredientLegendItems" xs12>
                                <v-divider></v-divider>
                                <v-item-group multiple>
                                    <v-subheader class="pl-0">
                                        {{ $t("Ingredient Legend Items") }}
                                    </v-subheader>
                                    <v-layout wrap>
                                        <v-select
                                            v-model="newItem.ingredient_legend_item_ids"
                                            :items="selectables"
                                            :item-text="item => item.name[selectedLanguage]"
                                            :item-value="item => item.id"
                                            chips
                                            multiple
                                            small-chips
                                            counter
                                            >
                                            </v-select>
                                    </v-layout>
                                </v-item-group>
                            </v-flex>

                            <v-flex v-if="hasTranslatableFile" xs12>
                                <v-flex xs12>
                                    <v-subheader class="pl-0">
                                        {{ $t("Bilddatei") }}
                                    </v-subheader>
                                </v-flex>
                                <v-flex
                                    v-for="lang in languages"
                                    :key="lang.iso"
                                    xs12
                                >
                                    <v-icon
                                        small
                                        class="mr-2"
                                        v-text="
                                            $vuetify.icons['flag_' + lang.iso]
                                        "
                                    ></v-icon>
                                    <media-selector
                                        v-model="file"
                                        :activator-label="$t('Select image')"
                                        :lang="lang.iso"
                                        :multiple="false"
                                    ></media-selector>
                                </v-flex>
                            </v-flex>
                            <v-divider></v-divider>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pb-2">
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" text @click="closing">
                    {{ $t("Cancel") }}
                </v-btn>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="success darken-3"
                    text
                    @click="saveNew"
                    >{{ $t("Save") }}</v-btn
                >
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { availableLanguages, defaultLanguageIso } from "~/config";

export default {
    components: {
        mediaSelector: require("$comp/shared/MediaValetSelector").default
    },

    props: {
        hasName: {
            type: [String, Boolean],
            default: false
        },
        hasSectors: {
            type: Boolean,
            default: false
        },
        hasStatement: {
            type: Boolean,
            default: false
        },
        hasCode: {
            type: Boolean,
            default: false
        },
        hasValue: {
            type: Boolean,
            default: false
        },
        hasIngredientLegendItems: {
            type: Boolean,
            default: false
        },
        hasFile: {
            type: Boolean,
            default: false
        },
        hasTranslatableFile: {
            type: Boolean,
            default: false
        },
        hasDescription: {
            type: Boolean,
            default: false
        },
        hasProductType: {
            type: Boolean,
            default: false
        },
        hasBrand: {
            type: Boolean,
            default: false
        },
        entity: {
            type: String,
            required: true
        },
        hasTemplate: {
            type: Boolean,
            required: false
        },
        hasLanguage: {
            type: Boolean,
            required: false
        },
        selectables: {
            type: Array,
            required: false
        }
    },

    data() {
        return {
            dialog: false,
            loading: false,
            selectedLanguage: defaultLanguageIso,
            defaultLanguageIso: defaultLanguageIso,
            languages: availableLanguages,
            file: {},
            name: {},
            newItem: {}
        };
    },

    computed: {
        ...mapGetters({
            tenants: "master-data/tenants",
            productTypes: "master-data/product-type",
            brands: "master-data/brand",
            tenant: "auth/tenant"
        })
    },

    watch: {
        file(newVal) {
            if (this.hasTranslatableFile) {
                this.newItem.file = newVal;
            }
        },
        dialog(newVal) {
            if (newVal === true) {
                this.reset();
            }
        }
    },

    mounted() {
        this.reset();
        if (this.productTypes.length < 1) {
            this.getData({ entity: "product-type", tenant: this.tenant });
        }

        if (this.brands.length < 1) {
            this.getData({ entity: "brand", tenant: this.tenant });
        }
    },

    methods: {
        ...mapActions({
            saveNewItem: "master-data/saveNewItem",
            getData: "master-data/getData"
        }),

        log(event, iso) {
            this.newItem.name[iso] = event;
        },

        reset() {
            this.newItem = {};
            if (this.hasName === "object") {
                this.newItem.name = this.languages
                    .map(l => l.iso)
                    .reduce((acc, curr) => ((acc[curr] = ""), acc), {});
            }

            if (this.hasName === "string") {
                this.newItem.name = "";
            }

            if (this.hasSectors === true) {
                this.newItem.is_pro = false;
                this.newItem.is_hobby = false;
                this.newItem.is_agric = false;
            }

            if (this.hasStatement) {
                this.newItem.statement = {};
            }

            if (this.hasDescription === true) {
                this.newItem.description = this.languages
                    .map(l => l.iso)
                    .reduce((acc, curr) => ((acc[curr] = ""), acc), {});
            }

            if (this.hasTranslatableFile) {
                this.newItem.file = {};
            }

            if (this.hasIngredientLegendItems) {
                this.newItem.ingredient_legend_item_ids = [];
            }
        },

        closing() {
            this.dialog = false;
            this.$emit("close");
        },

        saveNew() {
            this.loading = true;
            let payload = {
                entity: this.entity,
                item: { ...this.newItem, tenant: this.tenant }
            };

            this.saveNewItem(payload)
                .then(() => {
                    this.dialog = false;
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("New item added"),
                        color: "success"
                    });
                    this.newItem = {};
                    this.file = {};
                    this.reset();
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
