<template>
    <v-container fluid grid-list-xl class="product-edit">
        <v-layout justify-center wrap>
            <v-flex lg12 sm12 xs12>
                <v-layout justify-center class="mx-0">
                    <v-breadcrumbs class="pl-1" :items="crumbs">
                        <v-icon slot="divider">chevron_right</v-icon>
                    </v-breadcrumbs>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-flex xs2 class="mx-0 px-0 text-end">
                        <v-menu
                            left
                            bottom
                            offset-y
                            :close-on-click="true"
                            :close-on-content-click="false"
                        >
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    color="grey darken-1"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item v-if="!readonly" dense>
                                    <v-list-item-title>
                                        {{ $t("Deploy") }}
                                    </v-list-item-title>
                                    <v-list-item-action>
                                        <v-btn
                                            icon
                                            color="success"
                                            @click="doDeploy"
                                        >
                                            <v-icon>mdi-check-decagram</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-if="!readonly" dense>
                                    <v-list-item-title>
                                        Kopieren
                                    </v-list-item-title>
                                    <v-list-item-action>
                                        <productFieldsSync
                                            :readonly="readonly"
                                            :selected-language="productLanguage"
                                        />
                                    </v-list-item-action>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-title>
                                        Informationen
                                    </v-list-item-title>
                                    <v-list-item-action>
                                        <v-btn
                                            icon
                                            color="green"
                                            @click="openMeta = true"
                                        >
                                            <v-icon
                                                >mdi-information-outline</v-icon
                                            >
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        {{ $t("Translation") }}
                                    </v-list-item-title>
                                    <v-list-item-action>
                                        <v-select
                                            v-model="productLanguage"
                                            :items="
                                                product.tenant &&
                                                    product.tenant.settings
                                                        .product_validation
                                                        .available_languages
                                            "
                                            item-text="label"
                                            item-value="iso"
                                            class="lang-select mr-2"
                                        ></v-select>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-flex>
                </v-layout>

                <div class="elevation-1">
                    <v-tabs v-model="tab">
                        <v-tabs-slider color="accent"></v-tabs-slider>
                        <v-tab v-for="item in items" :key="item.key">
                            {{ $t(item.title) }}
                        </v-tab>
                    </v-tabs>
                    <v-card px0 class="elevation-0">
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="item in items" :key="item.key">
                                <component
                                    :is="item.key"
                                    v-if="Object.keys(product).length > 0"
                                    :language="productLanguage"
                                    :product="product"
                                    :readonly="readonly"
                                    :manage-product="manageProduct"
                                    :fallback-language="fallbackLanguage"
                                    @updateProduct="updateProduct"
                                ></component>
                            </v-tab-item>
                        </v-tabs-items>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <span
                                v-if="product.created_at"
                                class="font-italic grey--text"
                            >
                                {{ $t("Imported at") }}:
                                {{
                                    moment(product.created_at.date).format(
                                        "DD.MM.YYYY"
                                    )
                                }}
                            </span>
                            <span
                                v-if="
                                    product.created_at && product.published_at
                                "
                                class="grey--text lighten-2"
                                >&nbsp;|&nbsp;</span
                            >
                            <span
                                v-if="product.published_at"
                                class="font-italic grey--text primary--text lighten-3"
                            >
                                {{ $t("Published at") }}:
                                {{
                                    moment(product.published_at.date).format(
                                        "DD.MM.YYYY"
                                    )
                                }}
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn
                                :loading="saving"
                                color="success darken-3"
                                text
                                v-if="tab !== 9"
                                :disabled="!manageProduct && readonly"
                                @click="storeProduct"
                                >{{ $t("Save") }}</v-btn
                            >
                            <v-btn
                                active-class
                                color="error darken-1"
                                text
                                v-if="tab !== 9"
                                @click="cancelEdit"
                                >{{ $t("Cancel") }}</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </div>
            </v-flex>
        </v-layout>
        <metadata
            :product="product"
            :readonly="readonly"
            :open="openMeta"
            @close="openMeta = false"
            @updateProduct="updateProduct"
        />
    </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { defaultLanguageIso } from "~/config";

export default {
    components: {
        basic: require("$comp/product/edit/ProductEditBasic").default,
        logistics: require("$comp/product/edit/ProductEditLogistics").default,
        texts: require("$comp/product/edit/ProductEditTexts").default,
        media: require("$comp/product/edit/ProductEditMedia").default,
        attributes: require("$comp/product/edit/ProductEditAttributes").default,
        declarations: require("$comp/product/edit/ProductEditDeclarations")
            .default,
        relations: require("$comp/product/edit/ProductEditRelations").default,
        prices: require("$comp/product/edit/ProductEditPrices").default,
        labels: require("$comp/product/edit/ProductEditLabels").default,
        productFieldsSync: require("$comp/shared/ProductFieldsSync").default,
        metadata: require("$comp/product/edit/ProductMetaData").default,
        printLabels: require("$comp/product/edit/ProductEditPrintLabels").default
    },

    async beforeRouteLeave(to, from, next) {
        clearInterval(this.lockInterval);
        const answer = await this.saveChangesDialog();
        if (answer) {
            this.resetEdited(this.product.id);
        }
        next(answer);
    },

    props: {
        readonly: {
            type: Boolean,
            required: true
        },
        manageProduct: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            openMeta: false,
            productLanguage: defaultLanguageIso,
            fallbackLanguage: defaultLanguageIso,
            languages: [],
            saving: false,
            notifications: false,
            moment: moment,
            lockInterval: null,
            widgets: false,
            tab: 0,
            iterations: 0,

            items: [
                {
                    title: "Basic",
                    icon: "fa-info-circle",
                    key: "basic"
                },
                {
                    title: "Logistics",
                    icon: "fa-truck-loading",
                    key: "logistics"
                },
                {
                    title: "Texts",
                    icon: "fa-file-alt",
                    key: "texts"
                },
                {
                    title: "Media",
                    icon: "fa-file-alt",
                    key: "media"
                },
                {
                    title: "Attributes",
                    icon: "fa-tags",
                    key: "attributes"
                },
                {
                    title: "Declarations",
                    icon: "fa-link",
                    key: "declarations"
                },
                {
                    title: "Labels",
                    icon: "fa-puzzle-piece",
                    key: "labels"
                },
                {
                    title: "Relations",
                    icon: "fa-puzzle-piece",
                    key: "relations"
                },
                {
                    title: "Prices",
                    icon: "fa-money",
                    key: "prices"
                },
                {
                    title: "Print labels",
                    icon: "fa-printer",
                    key: "printLabels"
                },
            ]
        };
    },

    computed: {
        ...mapGetters({
            product: "products/productToEdit",
            editedProducts: "products/productEdited",
            loading: "ui/loading",
            tenant: "auth/tenant"
        }),

        crumbs() {
            let label = "";
            if (Object.keys(this.product).length !== 0) {
                label = this.$t("Item") + " " + this.product.item_number;
            }

            return [
                {
                    text: this.$t("Dashboard"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: false,
                    href: "/products"
                },
                {
                    text: label,
                    disabled: true,
                    href: "/products/" + this.$route.params.id
                }
            ];
        }
    },

    mounted() {
        this.setLoading(true);
        const fetchP = this.readonly
            ? this.fetchProduct
            : this.fetchProductToEdit;
        fetchP({
            id: this.$route.params.id,
            version: this.$route.params.version
        }).then(() => {
            let scope = "hobby";
            if (this.product.is_pro) {
                scope = "pro";
            }
            if (this.product.is_agric) {
                scope = "agric";
            }
            this.getAttributes({
                tenant: this.product.tenant.abbreviation,
                scope: scope,
            }).then(() => {
                this.setLoading(false);
            });
        });

        // lock product
        if (!this.readonly && this.$route.params.id) {
            this.lock(this.$route.params.id);

            this.lockInterval = setInterval(() => {
                this.iterations++;
                this.lock(this.$route.params.id);
                if (this.iterations > 20) {
                    clearInterval(this.lockInterval);
                }
            }, 60 * 1000);
        }
    },

    methods: {
        ...mapActions({
            fetchProductToEdit: "products/fetchProductToEdit",
            fetchProduct: "products/fetchProduct",
            deploy: "products/deployDraft",
            lock: "products/lockDraft",
            resetEdited: "products/resetEdited",
            updateProductFields: "products/updateProductFields",
            storeEdited: "products/storeEdited",
            setLoading: "ui/setLoading",
            getAttributes: "master-data/getAttributes"
        }),

        isDirty() {
            const p = this.$store.getters["products/productEdited"][
                this.$route.params.id
            ];
            if (p) {
                return Object.keys(p).length > 0;
            }
            return false;
        },

        updateProduct: function(e, field) {
            let payload = {};

            if (e !== null && typeof e === "object" && e.target !== undefined) {
                payload = {
                    productId: this.product.id,
                    field: field,
                    value: e.target.value
                };
            } else {
                payload = {
                    productId: this.product.id,
                    field: field,
                    value: e
                };
            }
            this.updateProductFields(payload);
        },

        storeProduct: function() {
            const mode = !this.readonly ? "edit" : "live";
            this.saving = true;
            this.storeEdited({ id: this.product.id, mode: mode })
                .then(() => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Erfolgreich gespeichert"),
                        color: "success"
                    });
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        async cancelEdit() {
            const answer = await this.saveChangesDialog();
            if (answer) {
                this.resetEdited(this.product.id);
                this.$router.go(-1);
            }
        },

        async doDeploy() {
            if (confirm("Den aktuellen Entwurf wirklich verwenden?")) {
                this.saving = true;
                this.deploy(this.product.id)
                    .then(() => {
                        this.$store.dispatch("ui/showSnackbar", {
                            text: this.$t("Erfolgreich deployed!"),
                            color: "success"
                        });
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            }
        },

        async saveChangesDialog() {
            if (this.isDirty()) {
                let answer = await this.$confirm(
                    this.$t(
                        "Do you really want to leave? You have unsaved changes!"
                    ),
                    { title: "Warning" }
                );
                if (answer) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        }
    }
};
</script>

<style>
.card--flex-toolbar {
    margin-top: -64px;
}

.lang-select {
    max-width: 130px;
    display: inline-flex;
}
</style>
