<template>
    <v-container fluid grid-list-xl class="master-data">
        <v-layout wrap>
            <v-flex lg10 sm10 xs12>
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </v-flex>
            <v-flex lg2 sm1 xs12>
                <sidemenu
                    class="mt-5"
                    :threshold="67"
                    :offset="20"
                    :items="items"
                ></sidemenu>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    components: {
        sidemenu: require("$comp/ui/Sidemenu").default
    },

    data: () => ({
        items: []
    }),

    mounted() {
        this.navigation();
    },

    methods: {
        navigation() {
            this.items = [
                {
                    title: "Application sites",
                    icon: false,
                    to: {
                        name: "applicationSites",
                        params: { entity: "application-site" }
                    },
                    exact: true
                },
                {
                    title: "Organic labels",
                    icon: false,
                    to: {
                        name: "organicLabels",
                        params: { entity: "organic-label" }
                    },
                    exact: true
                },
                {
                    title: "Chemical types",
                    icon: false,
                    to: {
                        name: "chemicalTypes",
                        params: { entity: "chemical-type" }
                    },
                    exact: true
                },
                {
                    title: "Characteristics",
                    icon: false,
                    to: {
                        name: "characteristics",
                        params: { entity: "characteristic" }
                    },
                    exact: true
                },
                {
                    title: "disposal-notes",
                    icon: false,
                    to: {
                        name: "disposalNotes",
                        params: { entity: "disposal-note" }
                    },
                    exact: true
                },
                {
                    title: "Hazard symbols",
                    icon: false,
                    to: {
                        name: "hazardSymbols",
                        params: { entity: "hazard-symbol" }
                    },
                    exact: true
                },
                {
                    title: "Hazard statements",
                    icon: false,
                    to: {
                        name: "hazardStatements",
                        params: { entity: "hazard-statement" }
                    },
                    exact: true
                },
                {
                    title: "Distributions",
                    icon: false,
                    to: {
                        name: "distributions",
                        params: { entity: "distribution" }
                    },
                    exact: true
                },
                {
                    title: "storage-notes",
                    icon: false,
                    to: {
                        name: "storageNotes",
                        params: { entity: "storage-note" }
                    },
                    exact: true
                },
                {
                    title: "Brands",
                    icon: false,
                    to: { name: "brands", params: { entity: "brand" } },
                    exact: true
                },
                {
                    title: "emergency-information",
                    icon: false,
                    to: {
                        name: "emergencyInformation",
                        params: { entity: "emergency-information" }
                    },
                    exact: true
                },
                {
                    title: "plants",
                    icon: false,
                    to: { name: "plants", params: { entity: "plant" } },
                    exact: true
                },
                {
                    title: "Plant groups",
                    icon: false,
                    to: {
                        name: "plantGroups",
                        params: { entity: "plant-group" }
                    },
                    exact: true
                },
                {
                    title: "Product types",
                    icon: false,
                    to: {
                        name: "productTypes",
                        params: { entity: "product-type" }
                    },
                    exact: true
                },
                {
                    title: "Product groups",
                    icon: false,
                    to: {
                        name: "productGroups",
                        params: { entity: "product-group" }
                    },
                    exact: true
                },
                {
                    title: "Product group codes",
                    icon: false,
                    to: {
                        name: "productGroupCodes",
                        params: { entity: "product-group-code" }
                    },
                    exact: true
                },
                {
                    title: "Product lines",
                    icon: false,
                    to: {
                        name: "productLines",
                        params: { entity: "product-line" }
                    },
                    exact: true
                },
                {
                    title: "Precautionary statements",
                    icon: false,
                    to: {
                        name: "precautionaryStatements",
                        params: { entity: "precautionary-statement" }
                    },
                    exact: true
                },
                {
                    title: "Tasks",
                    icon: false,
                    to: { name: "tasks", params: { entity: "task" } },
                    exact: true
                },
                {
                    title: "Seasons",
                    icon: false,
                    to: { name: "seasons", params: { entity: "season" } },
                    exact: true
                },
                {
                    title: "Application scopes",
                    icon: false,
                    to: {
                        name: "applicationScopes",
                        params: { entity: "application-scope" }
                    },
                    exact: true
                },
                {
                    title: "Task areas",
                    icon: false,
                    to: {
                        name: "taskAreas",
                        params: { entity: "task-area" }
                    },
                    exact: true
                },
                {
                    title: "Purposes",
                    icon: false,
                    to: { name: "purpose", params: { entity: "purpose" } },
                    exact: true
                },
                {
                    title: "Effect duration",
                    icon: false,
                    to: {
                        name: "effectDurations",
                        params: { entity: "effect-duration" }
                    },
                    exact: true
                },
                {
                    title: "Vats",
                    icon: false,
                    to: {
                        name: "vats",
                        params: { entity: "vat" }
                    },
                    exact: true
                },
                {
                    title: "Label templates",
                    icon: false,
                    to: {
                        name: "labelTemplates",
                        params: { entity: "label-template" }
                    },
                    exact: true
                },
                {
                    title: "Ingredients",
                    icon: false,
                    to: {
                        name: "ingredients",
                        params: { entity: "ingredients" }
                    },
                    exact: true
                },
                {
                    title: "Ingredient Legend Items",
                    icon: false,
                    to: {
                        name: "ingredientLegendItems",
                        params: { entity: "ingredient-legend-item" }
                    },
                    exact: true
                },
                {
                    title: "Fertilizer categories",
                    icon: false,
                    to: {
                        name: "fertilizerCategories",
                        params: { entity: "fertilizer-category" }
                    },
                    exact: true
                },
                
            ];
        }
    }
};
</script>
